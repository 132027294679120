import React, { Component } from 'react'
import { css } from '@emotion/react'
import * as Libs from 'libs'

import * as Atoms from '../atoms'

class InitialLoader extends Component {
  constructor (props) {
    super(props)

    this.container = React.createRef()
  }

  componentDidMount () {}

  render () {
    const initialLoaderCss = css`
      pointer-events: none;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100%;
      z-index: 10000;

      .bg {
        background-color: #fff;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        top: 0px;
      }
    `
    const containerCss = () => {
      return css`
        position: absolute;
        z-index: 100000;
        top: 50%;
        left: 50vw;
        margin: -27px 0 0 -39px;
        .load-text {
          position: absolute;
        }
        .spinner {
          position: absolute;
          top: -2px;
          left: 22px;
          z-index: 100000;
          width: 36px;
          height: 36px;
          ${Libs.Styles.Mq.sp} {
            width: 22px;
            height: 22px;
            left: 15px;
          }
          .init-load &,
          .page-load & {
            animation: rotate 2s linear infinite;
          }
        }
        & .path {
          stroke-linecap: round;
          .init-load &,
          .page-load & {
            animation: dash 1.5s ease-in-out infinite;
          }
        }

        @keyframes rotate {
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes dash {
          0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
          }
        }
      `
    }

    return (
      <div css={initialLoaderCss}>
        <div css={containerCss}>
          <Atoms.LoadingText />
          <svg className='spinner' viewBox='0 0 50 50'>
            <defs>
              <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                <stop offset='0%' stopColor='#6d4698' />
                <stop offset='30%' stopColor='#b74393' />
                <stop offset='60%' stopColor='#e84273' />
                <stop offset='100%' stopColor='#f5c85e' />
              </linearGradient>
            </defs>
            <circle
              className='path'
              stroke='url(#gradient)'
              cx='25'
              cy='25'
              r='20'
              fill='none'
              strokeWidth='8'
            ></circle>
          </svg>
        </div>

        <div className='bg' />
      </div>
    )
  }
}

export default InitialLoader
