import React, { useEffect, useContext, useState } from 'react'

import HeaderLogo from '../../components/modules/HeaderLogo'
import { css } from '@emotion/react'

import * as Modules from '../modules'
import * as Atoms from '../atoms'

import * as Libs from 'libs'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const headerCss = () =>
  css`
    position: fixed;

    mix-blend-mode: difference;
    z-index: 100;
    width: 100vw;
    box-sizing: border-box;
    height: 119px;
    margin: 0 auto;
    pointer-events: none;
    .on-menu & {
      mix-blend-mode: normal;
      pointer-events: auto;
    }

    .init-loaded:not(.on-scrollinit):not(.on-scrolldown):not(.on-scrollup) & {
    }
    ${Libs.Styles.Mq.moreTab} {
      margin-top: 1.5rem;
    }
    ${Libs.Styles.Mq.lessPab} {
      height: 95px;
      margin-top: 2rem;
    }
  `
const gridContCss = () => {
  return css`
    display: grid;
    transition: all 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);
    .type-category &,
    .type-post &,
    .type-page & {
      width: 92vw;
    }
    ${Libs.Styles.Mq.morePc} {
      grid-template-columns: 4fr 1fr;

      .category-index & {
        width: 96vw;
      }
    }
    ${Libs.Styles.Mq.tb} {
      grid-template-columns: 4fr 1fr;
      .category-index & {
        width: 94vw;
      }
    }
    ${Libs.Styles.Mq.lessPab} {
      grid-template-columns: 3fr 1fr;
      .category-index & {
        width: 90vw;
      }
    }

    margin: 0 auto;
  `
}

const lwlogoCss = () => {
  return css`
    overflow: hidden;

    display: inline-block;

    grid-column: 1 / 2;
    transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
    transform-origin: left top;
    .category-index.on-scrollinit & {
      ${Libs.Styles.Mq.moreTab} {
         {
          transform: scale(0.3);
        }
      }
      ${Libs.Styles.Mq.lessPab} {
         {
          transform: scale(0.5);
        }
      }
    }
    h1 {
      transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
      transform-origin: left top;
      transform: translateY(-180%);
      svg {
        fill: #fff;

        ${Libs.Styles.Mq.lessPab} {
          width: 100%;
          height: auto;
        }
      }
      .category-index & {
        visibility: visible;
        transform: translateY(0);
      }
      .now-transition & {
        transform: translateY(-180%);
      }
      .type-post &,
      .type-category & {
        pointer-events: none;
      }
    }
  `
}

const iconNav = () => {
  return css`
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    justify-self: right;
    overflow: hidden;

    color: #fff;
    cursor: pointer;
    .menu-icon {
      overflow: hidden;
      div {
        transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        transform-origin: left;
        transform: scaleX(0);
      }
    }
    .menu-text {
      display: inline-block;
      transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);

      transform: translateX(100%);
    }
    .type-page.on-index-gnav-out &,
    .type-post.on-gnav-out &,
    .type-category.on-index-gnav-out & {
      pointer-events: auto;
      .menu-icon {
        div {
          transition-delay: 0.5s;
          transform: scaleX(1);
        }
      }
      .menu-text {
        transition-delay: 0.75s;
        transform: translateX(0%);
      }
    }
    ${Libs.Styles.Mq.moreTab} {
      .type-page & {
        margin-top: 0px;
      }
      .type-post & {
        margin-top: -2px;
      }
    }
    ${Libs.Styles.Mq.lessPab} {
      margin-top: -3px;
      .type-page & {
      }
      .type-post & {
        margin-top: 20px;
      }
    }
  `
}
const infoCont = () => {
  return css`
    width: 50vw;
    z-index: 99;
    position: fixed;
    pointer-events: none;
    .category-index & {
      pointer-events: auto;
    }
    .on-menu & {
      pointer-events: none;
    }

    ${Libs.Styles.Mq.moreTab} {
      .category-index.on-scrollinit &,
      .now-transition & {
        pointer-events: none;
      }
    }
    ${Libs.Styles.Mq.lessPab} {
      .category-index.on-gnav-out &,
      .now-transition & {
        pointer-events: none;
      }
    }
    ${Libs.Styles.Mq.morePc} {
      margin-left: 2vw;
      margin-top: 105px;
    }
    ${Libs.Styles.Mq.tb} {
      margin-left: 3vw;
      margin-top: 105px;
    }
    ${Libs.Styles.Mq.lessPab} {
      left: 5vw;
      top: 150px;
    }
  `
}
const infoCss = () => {
  return css`
    ${Libs.Styles.Mq.moreTab} {
      margin-top: 1.75rem;
    }
    ${Libs.Styles.Mq.lessPab} {
      margin-top: 1rem;
    }
    .category-index & {
    }
    grid-column: 1 / span 1;
  `
}

const socialGridCss = type => {
  return css`
    div {
      overflow: hidden;
      p {
        transition: all 0.75s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        transform: translateY(-125%);
        .category-index & {
          transform: translateY(0%);
        }

        ${Libs.Styles.Mq.moreTab} {
          .category-index.on-scrollinit &,
          .now-transition & {
            transform: translateY(-125%);
          }
        }
        ${Libs.Styles.Mq.lessPab} {
          .category-index.on-gnav-out &,
          .now-transition & {
            transform: translateY(-125%);
          }
        }
      }
    }

    ${Libs.Styles.Mq.morePc} {
      grid-column: 1 / 2;
      display: grid;
      --grid-gap: 0.5rem;
      --grid-margin: 1.5rem;
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }
    ${Libs.Styles.Mq.tb} {
      grid-column: 1 / 2;
      display: grid;
      --grid-gap: 0.5rem;
      --grid-margin: 1.5rem;
      grid-template-columns: 2fr 1fr 1fr;
    }
    ${Libs.Styles.Mq.lessPab} {
      --grid-gap: 1rem;
      --grid-margin: 1.5rem;
    }
    column-gap: var(--grid-gap);
    row-gap: var(--grid-gap);
  `
}

const smallTextStyle = {
  fontPc: {
    fontSize: 16,
    bold: true,
    letterSpacing: '-.2'
  },
  fontSp: {
    fontSize: 14,
    lineHeight: 18,
    bold: true,
    letterSpacing: '-.2'
  }
}

const Header = props => {
  //--------------------------------------
  //  Scripts
  //--------------------------------------
  useEffect(() => {
    let initPosition
    if (
      Libs.Common.Func.getDeviceSize() !== 'lessPab' &&
      !Libs.Common.Config.isMobile()
    ) {
      initPosition = '200px'
    } else if (Libs.Common.Func.getDeviceSize() === 'lessPab') {
      initPosition = '150px'
    }
    ScrollTrigger.create({
      trigger: '.scroll-content',
      start: `top+=${initPosition} 0`,
      end: 'bottom+=100000 top',
      toggleClass: {
        targets: 'html',
        className: 'on-scrollinit'
      }
    })
  }, [])

  return (
    <>
      <header css={headerCss}>
        <Modules.Breadcrumb></Modules.Breadcrumb>
        <div css={gridContCss}>
          <div className='lw-logo' css={lwlogoCss}>
            <h1>
              <HeaderLogo />
            </h1>
          </div>
          <div css={iconNav} className='hamburger-menu'>
            <Modules.HamburgerMenu />
          </div>
        </div>
      </header>

      <div className='index-info-cont' css={infoCont}>
        <div css={socialGridCss}>
          <div>
            <Libs.Atoms.P styles={smallTextStyle}>Follow Us:</Libs.Atoms.P>
          </div>
          <div>
            <Libs.Atoms.P styles={smallTextStyle}>
              <a href='https://www.facebook.com/loworks' target='_new'>
                FACEBOOK
              </a>
            </Libs.Atoms.P>
          </div>
          <div>
            <Libs.Atoms.P styles={smallTextStyle}>
              <a href='https://www.instagram.com/loworks_inc/' target='_new'>
                INSTAGRAM
              </a>
            </Libs.Atoms.P>
          </div>
        </div>
        <div css={infoCss}>
          <Atoms.InfoContactForHader />
        </div>
      </div>
    </>
  )
}
export default Header
