import React, { PureComponent } from 'react'
import { css } from '@emotion/react'

import * as Libs from 'libs'
import * as Common from '../../common'
import * as Modules from '../modules'

export default props => {
  //--------------------------------------
  //  Styles
  //--------------------------------------

  const smallTextStyle = {
    fontPc: {
      fontSize: 14,
      bold: true,
      letterSpacing: '-.2'
    },
    fontSp: {
      fontSize: 14,

      bold: true,
      letterSpacing: '-.2'
    }
  }

  //--------------------------------------
  //  Css
  //--------------------------------------
  const gridContCss = () =>
    css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 350, 250, false)};
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      width: 92vw;
      margin-left: auto;
      margin-right: auto;
      ${Libs.Styles.Mq.moreTab} {
        column-gap: 2rem;
        row-gap: 8rem;
      }
      ${Libs.Styles.Mq.lessPab} {
        column-gap: 0.5rem;
        row-gap: 6rem;
      }
    `
  const loworksInfoCss = () => css`
    ${Libs.Styles.Mq.moreTab} {
      grid-column: 1 / 5;
    }
    ${Libs.Styles.Mq.lessPab} {
      grid-column: 1 / 10;
    }

    svg {
      ${Libs.Styles.Mq.moreTab} {
        width: 100%;
        height: auto;
      }
      ${Libs.Styles.Mq.lessPab} {
        width: 55vw;
        height: auto;
      }
    }
  `
  const socialGridCss = type => {
    return css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 8, 8, true)};
      p {
        display: inline-block;
      }
      .social-link-grid {
        justify-self: right;
        p {
          &:last-child {
            margin-left: 1.3vw;
            margin-right: 1.3vw;
          }
        }
      }
      ${Libs.Styles.Mq.moreTab} {
        display: grid;
        --grid-gap: 0.5rem;
        --grid-margin: 1.5rem;
        grid-template-columns: 1fr 2fr;
      }
      ${Libs.Styles.Mq.tb} {
        grid-column: 1 / 2;
        display: grid;
        --grid-gap: 0.5rem;
        --grid-margin: 1.5rem;
        grid-template-columns: 2fr 1fr 1fr;
      }
      ${Libs.Styles.Mq.lessPab} {
        --grid-gap: 1rem;
        --grid-margin: 1.5rem;
      }
      column-gap: var(--grid-gap);
      row-gap: var(--grid-gap);
    `
  }
  const contactCSS = () =>
    css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 60, 40, true)};
      > div {
        ${Libs.Common.Func.getPcSpVwValue('margin-top', 24, 24, true)};
        > h2 {
          ${Libs.Common.Func.getPcSpVwValue('margin-bottom', 12, 12, true)};
        }
      }
    `

  const profileContCss = () => css`
    ${Libs.Styles.Mq.moreTab} {
      grid-column: 7 / 13;
    }
    ${Libs.Styles.Mq.lessPab} {
      grid-column: 1 / -1;
    }
    h2 {
      margin-left: -2px;
    }
    > span {
      display: block;
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 8, 8, true)};
      ${Libs.Common.Func.getPcSpVwValue('margin-bottom', 36, 36, true)};
    }
    p {
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 36, 36, true)};
    }
  `
  const awardsCss = () => css`
    ${Libs.Styles.Mq.moreTab} {
      grid-column: 1 / 7;
    }
    ${Libs.Styles.Mq.lessPab} {
      grid-column: 1 / -1;
    }
  `
  const pressCss = () => css`
    ${Libs.Styles.Mq.moreTab} {
      grid-column: 7 / 13;
    }
    ${Libs.Styles.Mq.lessPab} {
      grid-column: 1 / -1;
    }
  `

  const workExperienceCss = () => css`
    grid-column: 1 / -1;
  `
  const H2Styles = {
    fontPc: {
      fontSize: 36,
      lineHeight: 36,
      bold: true,
      letterSpacing: '-.5'
    },
    fontSp: {
      fontSize: 22,
      lineHeight: 22,
      bold: true,
      letterSpacing: '-.5'
    }
  }

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const { className, ...rest } = props

  return (
    <section
      className={className}
      {...rest}
      data-categoryname={'About'}
      data-categoryslug={'about'}
      data-type={'page'}
    >
      <section css={gridContCss}>
        <div css={loworksInfoCss}>
          <Modules.HeaderLogo></Modules.HeaderLogo>
          <div css={socialGridCss}>
            <div>
              <Libs.Atoms.P styles={smallTextStyle}>Follow Us:</Libs.Atoms.P>
            </div>
            <div className='social-link-grid'>
              <Libs.Atoms.P styles={smallTextStyle}>
                <a href='https://www.facebook.com/loworks' target='_new'>
                  FACEBOOK
                </a>
              </Libs.Atoms.P>
              <Libs.Atoms.P styles={smallTextStyle}>
                <a href='https://www.instagram.com/loworks_inc/' target='_new'>
                  INSTAGRAM
                </a>
              </Libs.Atoms.P>
            </div>
          </div>
          <div css={contactCSS}>
            <div>
              <Libs.Atoms.H2 styles={H2Styles}>New York</Libs.Atoms.H2>
              <Libs.Atoms.P styles={Common.Config.getPStyles()}>
                266 Kent Ave, Brooklyn, NY 11249
              </Libs.Atoms.P>
            </div>
            <div>
              <Libs.Atoms.H2 styles={H2Styles}>Tokyo</Libs.Atoms.H2>
              <Libs.Atoms.P styles={Common.Config.getPStyles()}>
                3F 3-1-9 Shibuya Shibuya-ku
                <br />
                Tokyo 150-0002
              </Libs.Atoms.P>
            </div>
            <div>
              <Libs.Atoms.P styles={H2Styles}>
                <a href='mailto:info@loworks.co.jp' target='_new'>
                  E-MAIL
                </a>
              </Libs.Atoms.P>
            </div>
          </div>
        </div>
        <div css={profileContCss}>
          <Libs.Atoms.H2 styles={Common.Config.getH1Styles()}>
            Haruki Higashi
          </Libs.Atoms.H2>
          <Libs.Atoms.Span styles={smallTextStyle}>
            Founder / Art Director / Designer / Artist / Developer
          </Libs.Atoms.Span>
          <Libs.Atoms.P styles={Common.Config.getPStyles()}>
            Haruki was born in Kumamoto, Japan, in 1978. In 2004, he founded the
            design company, LOWORKS INC. The interactive content he has produced
            to promote his own illustrations has received numerous awards at
            home and abroad, including from the Japan Media Arts Festival.
            Following his initial success, he started doing creative work for
            prominent clients abroad, including Nike, WWF, Marlboro, and
            McDonald's, and for the TV anime, Space Shower. He has also provided
            unique website design for artists, both major and independent, such
            as Kaela Kimura and HIFANA. Throughout his career, he has been
            active as an art director, an illustrator, a graphic designer, and
            as a designer for interactive media.
          </Libs.Atoms.P>
          <Libs.Atoms.P styles={Common.Config.getPStyles()}>
            At the same time, Haruki has continued to pursue his own creative
            projects and activities. In 2004, he worked with the French studio,
            Elshopo, to develop a silk screen machine. It is sold made-to-order
            at art museums and other cultural spaces. In 2008, he worked as the
            supervisor on the "CAFE + SPACE BEYOND PROJECT," designing the
            graphics for the exterior walls, as well as the shop logo and the
            signs inside. His iPhone case design, with more than 5,000 sold
            through select stores, and the popularity of his collaborations with
            well-known apparel brands, "BARNEYS NEWYORK" and "And A," have also
            brought attention to his work.
          </Libs.Atoms.P>
          <Libs.Atoms.P styles={Common.Config.getPStyles()}>
            In 2018, Haruki launched Howlt, his own brand, which focuses on
            creating immersive art and design experiences. He went on to hold
            his solo exhibition, "Howlt Coffee," at UltraSuperNew Gallery in
            Harajuku, where he reflected on Tokyo's coffeehouse culture through
            design and typography. The following year, in 2019, he opened his
            own coffee shop, Howlt Coffee, in Fukuoka, Japan, as well as an
            online store of playful and unique products. Also in 2019, Haruki
            was placed in charge of the artwork for the first anniversary and
            Christmas advertisement of MarkIs, a large commercial facility in
            Fukuoka, for two consecutive months. In 2021, he was in charge of
            artwork for the Tokyo 2020 Olympic and Paralympic Games athlete
            trading card and NFT art production project by Adidas.
          </Libs.Atoms.P>
        </div>
        <Modules.AboutAwards css={awardsCss} />
        <Modules.AboutPress css={pressCss} />
        <Modules.AboutExhibition css={workExperienceCss} />
        <Modules.AboutWorkExperience css={workExperienceCss} />
        <Modules.AboutClientList />
      </section>
    </section>
  )
}
