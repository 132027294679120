import React, { PureComponent } from 'react'
import { elementHoc } from './ElementHoc'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as LibsAtoms from './'
import { css } from '@emotion/react'
import * as Styles from '../styles'
import { WindowResize } from '../redux/event/WindowResize'
import * as Common from '../common'
import { TimelineMax } from 'gsap'
import { Power3 } from 'gsap'

export class ImageElement extends PureComponent {
  constructor (props) {
    super(props)
    this.imgElement = React.createRef()
    this.state = { imgContHeight: 0 }
    this.imgCont = React.createRef()
    this.videoEl = React.createRef()
  }
  componentDidMount () {
    this.setState({
      imgContHeight: this.imgCont.clientWidth / this.ratio
    })

    if (this.videoEl.current !== null) {
      this.videoEl.addEventListener('emptied', event => {
        //console.log('imgLoadedList --- emptied', event)
      })
      this.videoEl.addEventListener('error', event => {
        // console.log('imgLoadedList --- onerror', event)
      })

      this.videoEl.addEventListener('loadedmetadata', event => {
        if (this.ratio === 1) {
          this.ratio = event.target.offsetWidth / event.target.offsetHeight
          this.setState({
            imgContHeight: this.imgCont.clientWidth / this.ratio
          })
        }

        const tl = new TimelineMax()

        const ele = this.videoEl
        const cont = ele.closest('.video-inner-cont')
        tl.to({}, 0.5, {})
          .call(function () {
            cont.classList.add('loaded')
            cont.style.height = 'auto'
          })
          .to(
            ele,
            0.5,
            {
              opacity: 1,
              ease: Power3.easeInOut
            },
            'end'
          )
          .to(
            cont,
            0.5,
            {
              backgroundColor: 'rgba(255, 255, 255, 0)',
              ease: Power3.easeInOut
            },
            'end'
          )
      })
    }
  }
  render () {
    let {
      className,
      onload,

      src,
      forwardedref,
      children,
      data,
      caption = null,
      videoController = true,

      ...rest
    } = this.props

    const getElementWidth = (props, type, parent) => {
      const keys =
        type === 'pc' ? ['widthPc', 'width_pc'] : ['widthSp', 'width_sp']
      return props[keys[0]] || props[keys[1]]
        ? props[keys[0]]
          ? props[keys[0]]
          : props[keys[1]]
        : parent && (parent[keys[0]] || parent[keys[1]])
        ? parent[keys[0]]
          ? parent[keys[0]]
          : parent[keys[1]]
        : 'auto'
    }

    const stylesObj = {
      display: 'block'
    }
    const styles = data.instance.styles

    const imgSrc = WindowResize.currentType(WindowResize.lessPab)
      ? styles.imageSp
        ? styles.imageSp
        : styles.image
      : styles.image
    const videoSrc = imgSrc.gatsbyImageData ? null : imgSrc.file.url
    const thumbnailForVideo =
      videoSrc && styles.thumbnailForVideo
        ? styles.thumbnailForVideo.file.url
        : null

    this.ratio =
      imgSrc && imgSrc.gatsbyImageData
        ? Math.floor(
            (imgSrc.gatsbyImageData.width / imgSrc.gatsbyImageData.height) *
              Math.pow(10, 2)
          ) / Math.pow(10, 2)
        : Number(imgSrc.description)
        ? Number(imgSrc.description)
        : 1

    styles.widthPc = getElementWidth(styles, 'pc')
    styles.widthSp = getElementWidth(styles, 'sp')

    let target = '_new'

    let link = this.props.data.instance.styles.link
    let lang = Common.Func.getLangKey()
    lang = lang ? `/${Common.Func.getLangKey()}` : ''

    if (link) {
      if (link.startsWith('mailto')) {
        link = `mailto:${link}`
        target = '_self'
      } else if (
        styles &&
        !link.startsWith('https://') &&
        !link.startsWith('http://')
      ) {
        link = `${lang}${link}`
        target = '_self'
      }
    }

    const classStr = data.instance.styles.class

    const imgContCss = () => {
      const objectFit = styles.objectFit ? styles.objectFit : 'cover'
      const objectFitSp = styles.objectFitSp
        ? styles.objectFitSp
        : styles.objectFit
        ? styles.objectFit
        : 'cover'
      const heightValue = this.state.imgContHeight
      return css`
        .video-inner-cont {
          height: ${heightValue}px;
        }
        .gatsby-image-wrapper {
          ${Styles.Mq.lessPab} {
            height: ${heightValue}px;
          }
          img {
            ${Styles.Mq.moreTab} {
              object-fit: ${objectFit} !important;
            }
            ${Styles.Mq.lessPab} {
              object-fit: ${objectFitSp} !important;
            }
          }
        }
      `
    }
    const videoContCss = () => {
      return css`
        position: relative;
        .button-volume {
        }
      `
    }
    const videoElementCss = () => {
      return css`
        width: 100%;
      `
    }

    const volumeButtonCss = () => {
      return css`
        position: absolute !important;
        bottom: 12px;
        right: 12px;
        z-index: 10;
      `
    }

    const getLinkElement = () => {
      return (
        <>
          {link === '#' ||
          link.startsWith('https://') ||
          link.startsWith('http://') ||
          link.startsWith('mailto') ? (
            <a
              href={link}
              target={target}
              className={`${className ? className : ''}`}
            >
              {videoSrc ? (
                getVideoElement()
              ) : imgSrc && imgSrc.gatsbyImageData ? (
                <GatsbyImage
                  onLoad={props => {
                    if (onload) onload()
                  }}
                  image={imgSrc.gatsbyImageData}
                  alt={imgSrc.title}
                />
              ) : (
                ''
              )}
              {caption ? (
                <LibsAtoms.P
                  className={'caption'}
                  styles={{
                    fontPc: {
                      fontSize: 14,
                      lineHeight: 15,
                      textAligh: 'center'
                    },
                    fontSp: {
                      fontSize: 14,
                      lineHeight: 15,
                      textAligh: 'center'
                    },

                    positionPc: { space: '10 0 0 0' },
                    positionSp: { space: '10 0 0 0' }
                  }}
                >
                  {caption}
                </LibsAtoms.P>
              ) : (
                ''
              )}
            </a>
          ) : (
            <>
              <LibsAtoms.TriggerLink
                to={`${link}/`}
                className={`${className ? className : ''}`}
              >
                {videoSrc ? (
                  getVideoElement()
                ) : imgSrc && imgSrc.gatsbyImageData ? (
                  <GatsbyImage
                    onLoad={props => {
                      if (onload) onload()
                    }}
                    image={imgSrc.gatsbyImageData}
                    alt={imgSrc.title}
                  />
                ) : (
                  ''
                )}
              </LibsAtoms.TriggerLink>
              {caption ? (
                <LibsAtoms.P
                  className={'caption'}
                  styles={{
                    spFontSize: 11,
                    spLineHeight: 14,
                    spacingPc: { top: 10 },
                    spacingSp: { top: 10 }
                  }}
                >
                  {caption}
                </LibsAtoms.P>
              ) : (
                ''
              )}
            </>
          )}
        </>
      )
    }
    const getVideoElement = () => {
      return (
        <div css={videoContCss} className='video-inner-cont'>
          {/*<LibsAtoms.ButtonResume
                clickHandler={props => {
                  if (this.videoEl.paused) {
                    this.videoEl.play()
                  } else {
                    this.videoEl.pause()
                  }

                  return false
                }}
                id={styles.id}
              />*/}
          {videoController ? (
            <LibsAtoms.ButtonVolume
              clickHandler={props => {
                if (this.videoEl.muted) {
                  this.videoEl.muted = false
                } else {
                  this.videoEl.muted = true
                }

                return false
              }}
              id={styles.id}
              css={volumeButtonCss}
            />
          ) : (
            ''
          )}
          <video
            css={videoElementCss}
            autoplay='autoplay'
            playsinline='1'
            loop='1'
            disablepictureinpicture='1'
            preload='1'
            data-plugin='videoAutoPlay'
            muted='0'
            poster={thumbnailForVideo}
            onLoadedData={event => {
              if (onload) onload(this)
            }}
            onError={event => {
              console.log('imgLoadedList --- error ', event)
            }}
            onMouseDown={props => {
              this.videoEl.pause()

              return false
            }}
            onTouchStart={props => {
              this.videoEl.pause()

              return false
            }}
            onMouseUp={props => {
              this.videoEl.play()

              return false
            }}
            onTouchEnd={props => {
              this.videoEl.play()

              return false
            }}
            ref={el => {
              if (el !== null) el.setAttribute('muted', 1)
              this.videoEl = el
            }}
          >
            <source src={videoSrc} type='video/mp4' />
          </video>
        </div>
      )
    }

    const Tag = styles.containerTag ? styles.containerTag : 'div'

    return (
      <Tag
        ref={[
          forwardedref,
          el => {
            this.imgCont = el
          }
        ]}
        css={[data.func(stylesObj, data.instance)(), imgContCss()]}
        className={`${className ? className : ''} img-cont ${
          onload ? 'img-element' : ''
        } ${classStr ? classStr : ''}`}
        {...rest}
      >
        <div
          className={'img-inner-cont'}
          ref={el => {
            this.imgCont = el
          }}
        >
          {link ? (
            getLinkElement()
          ) : videoSrc ? (
            getVideoElement()
          ) : imgSrc && imgSrc.gatsbyImageData ? (
            <>
              <GatsbyImage
                placeholder='blurred'
                onLoad={props => {
                  if (onload) onload()
                }}
                image={imgSrc.gatsbyImageData}
                alt={imgSrc.title}
              />
              {caption ? (
                <LibsAtoms.P
                  className={'caption'}
                  styles={{
                    fontPc: {
                      fontSize: 14,
                      lineHeight: 15,
                      textAlign: 'center',
                      fontFace: 'sansserif'
                    },
                    fontSp: {
                      fontSize: 14,
                      lineHeight: 15,
                      fontFace: 'sansserif',
                      textAlign: 'center'
                    },

                    positionPc: { space: '10 0 0 0' },
                    positionSp: { space: '10 0 0 0' }
                  }}
                >
                  {caption}
                </LibsAtoms.P>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </Tag>
    )
  }
}

export default elementHoc(ImageElement)
