import React from 'react'
import { css } from '@emotion/react'

import * as Libs from 'libs'
import * as Common from '../../common'

export default props => {
  //--------------------------------------
  //  Css
  //--------------------------------------

  const pStyles = {
    fontPc: {
      fontSize: 14,
      lineHeight: 14,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    },
    fontSp: {
      fontSize: 14,
      lineHeight: 14,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    }
  }

  //--------------------------------------
  //  Css
  //--------------------------------------

  const gridContCss = () =>
    css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 24, 24, true)};
      li {
        width: 100%;
        display: grid;
        column-gap: 2rem;

        position: relative;
        &::after {
          content: '';
          background-color: #000;
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 0px;
        }

        ${Libs.Styles.Mq.moreTab} {
          grid-template-columns: 2fr 6fr 5fr 1fr;
          row-gap: 2rem;
          padding: 1rem 0 1.2rem 0;
          span {
            &:last-child {
              justify-self: right;
            }
          }
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-template-columns: 1fr 1fr;
          row-gap: 0.5rem;
          padding: 0.75rem 0 1rem 0;
          span {
            &:nth-child(2) {
              grid-column: 1 / 2;
            }
            &:nth-child(3) {
              grid-column: 2 / 3;
              grid-row: 1 / 2;
              text-align: right;
              justify-self: right;
            }
            &:last-child {
              grid-column: 2 / 3;
              justify-self: right;
            }
          }
        }
      }
    `

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const { ...rest } = props

  return (
    <div styles={pStyles} {...rest}>
      <Libs.Atoms.H2 styles={Common.Config.getH2Styles()}>
        Work Experience
      </Libs.Atoms.H2>
      <ul css={gridContCss}>
        <li>
          <Libs.Atoms.Span styles={pStyles}>adidas</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            adidas Tokyo Olympics 2020 Trading card & NFT art Global
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Artwork</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2022</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>NowHere NYC</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Branding work</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2021</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Amazon Prime</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>BadMood</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Illustration</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2021</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            FOLIE à PLUSIEURS perfume
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Website Development
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2020</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Folie Studio</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Website Development
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2020</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Howlt Coffee</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Store Opened in Fukuoka Japan
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Produce Artwork Design Development Management
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2019</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>MarkIs Fukuoka</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            1st Anniversary and X'mas Campaign
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Artwork</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2019</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>kobunsha</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Very Official Site</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2017</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Smirnoff</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Halloween campaign</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Artwork</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2017</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>docomo</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>PETOCO HOME DEVICE</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Character Design</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2017</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Lark</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>CREATIVE WORK</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Graphic</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2017</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Coleman</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Outdoor resort park 2016
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2016</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Coleman</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Outdoor resort park 2015
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2015</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Sony Music</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Revalcy Official Site
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2014</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Victor Music</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Kaela Kimura 10 years anniversar
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            illustration Design Development
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2014</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>ELLE</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Levis Advertisment</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Artwork Design</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2014</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Audi</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            2013 Promotion Works
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2013</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>NEC</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Smartcity</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Design illstration Animation
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2013</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Hoshino Resort</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Global Website</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2013</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Audi</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            2012 Promotion Works
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2012</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Wacom</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>BambooPop</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Artwork Design Development
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2012</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Wacom</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>E motion</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Artwork Design Development
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2012</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>WWF</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            little Planet (App)
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Artwork Design Development
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2010</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Marlboro</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Yorunori Campaign</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2010</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>McDonald's</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Coffee for two</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Artwork Design</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2010</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Nike</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Yama wo koe</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Artwork Design</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2010</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>CITROEN</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>C4 Campaign</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2010</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Marlboro</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Marlboro Black Gold
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2009</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Nike</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Nike x Yahoo PR</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Artwork Design</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2008</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Hitachi Data Systems
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>VIBPLID Promotion</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Artwork</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2007</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Ford</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            Make Drawing Exciting
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Animation</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2007</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Nintendo</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            ELECTROPLANKTON Offical Site
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Design Development</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2006</Libs.Atoms.Span>
        </li>
      </ul>
    </div>
  )
}
