import React, { PureComponent } from "react";
import { elementHoc } from "libs/atoms/ElementHoc";

export class Logo02 extends PureComponent {
	render() {
		let {
			className,
			forwardedref,
			children,
			data,

			...rest
		} = this.props;

		const stylesObj = {
			display: "block",
		};

		return (
			<svg
				ref={forwardedref}
				css={data.func(stylesObj, data.instance)}
				className={`${className ? className : ""} `}
				{...rest}
				viewBox="0 0 252 36"
				preserveAspectRatio="xMinYMin meet"
			>
				<use xlinkHref="#hclogo" x="0" y="0" />
			</svg>
		);
	}
}

export default elementHoc(Logo02);
