import React from 'react'
import { css } from '@emotion/react'

import * as Libs from 'libs'
import * as Common from '../../common'

export default props => {
  //--------------------------------------
  //  Css
  //--------------------------------------

  const pStyles = {
    fontPc: {
      fontSize: 14,
      lineHeight: 14,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    },
    fontSp: {
      fontSize: 14,
      lineHeight: 14,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    }
  }

  //--------------------------------------
  //  Css
  //--------------------------------------

  const gridContCss = () =>
    css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 24, 24, true)};
      li {
        width: 100%;
        display: grid;
        column-gap: 2rem;
        row-gap: 2rem;
        grid-template-columns: 1fr;
        padding: 1rem 0 1.2rem 0;
        position: relative;
        &::after {
          content: '';
          background-color: #000;
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 0px;
        }
      }
    `

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const { ...rest } = props

  return (
    <div styles={pStyles} {...rest}>
      <Libs.Atoms.H2 styles={Common.Config.getH2Styles()}>Awards</Libs.Atoms.H2>
      <ul css={gridContCss}>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Japan Media Arts Festival
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Good design awards</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Yahoo Internet creative award
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Tokyo Interactive ad awards
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Asia's Creative Advertising Festival and Awards
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            BDA WORLD GOLD AWARDS
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>awwwards</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>CSS Design awards</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>theFWA Awards</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>CSS REEL</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>CSS WINNER</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Netdiver Best of the Year
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>Moluvs Pick Award</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Internet TINY Award
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>WellVetted</Libs.Atoms.Span>
        </li>
      </ul>
    </div>
  )
}
