import React, { useContext, useState } from 'react'

import { css } from '@emotion/react'
import * as Common from '../../common'

import { useDispatch } from 'react-redux'

import * as Libs from 'libs'

const GlobalNavigation = props => {
  const { styles, onClick, ...rest } = props

  //--------------------------------------
  //  Scripts
  //--------------------------------------

  const dispatch = useDispatch()

  const langKey = Libs.Common.Func.getLangKey()
  const navItems = Common.Config.getNavItems()
  return (
    <div>
      <ul>
        {navItems.map((item, i) => (
          <li key={`navItem${i}`}>
            <Libs.Atoms.Span data-hover={item.label} styles={styles}>
              <Libs.Atoms.TriggerLink
                transition={
                  Libs.Transition.TrigerPageTransition.TrigerPageTransition
                }
                to={
                  item.to !== '/visit'
                    ? `/${langKey}${item.to}/`
                    : `${item.to}/`
                }
                onClick={onClick}
              >
                {item.label}
              </Libs.Atoms.TriggerLink>
            </Libs.Atoms.Span>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default GlobalNavigation
