import React, { useState, useRef, useEffect } from 'react'

import { css } from '@emotion/react'

import * as Libs from 'libs'

const Dropdown = ({
  active,
  styles = {
    fontPc: { fontFace: 'sunsserif', fontSize: '14' },
    fontSp: { fontFace: 'sunsserif', fontSize: '14' }
  },
  items = [],
  trigerLink,
  onMenuClick,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const activatorRef = useRef()
  const listRef = useRef()

  const handleClick = () => {
    toggleActiveClass(!isOpen)
  }
  const contCss = () =>
    css`
      position: relative;
      .activator {
        align-items: center;
        background-color: inherit;
        color: inherit;
        display: flex;
        cursor: pointer;
        line-height: 1;
        display: inline-flex;
        ${Libs.Styles.Mq.morePc} {
          max-width: 4.5vw;
        }
        ${Libs.Styles.Mq.lessTab} {
          max-width: 60px;
        }
        &:after {
          content: '';
          border-bottom: 2px solid #000;
          border-right: 2px solid #000;
          height: 0.5em;
          margin-left: 10px;
          margin-top: -5px;
          width: 0.5em;
          transform: rotate(45deg);
        }
      }
      .selected {
        pointer-events: none;
        opacity: 0.3;
      }
      .span-element {
        pointer-events: none;
      }
      .itemList {
        background-color: #ececec;
        border-radius: 8px;
        padding-top: 5px;
        padding-bottom: 6px;
        color: black;
        display: none;
        margin: 0;
        text-align: center;
        min-width: 40px;
        position: absolute;

        & > div {
          list-style: none;
          margin: 0;

          a,
          a:link {
            display: block;
          }
        }
        &.active {
          display: block;
        }
        .item {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
    `
  const handleClickOutside = event => {
    if (
      listRef.current.contains(event.target) ||
      (activatorRef.current && activatorRef.current.contains(event.target))
    ) {
      return
    }
    toggleActiveClass(false)
  }
  const linkHandler = (event, item) => {
    toggleActiveClass(false)
    if (onMenuClick) onMenuClick()
    item.onClick(event)
  }
  const toggleActiveClass = isOpen => {
    const cont = document.querySelector('.dropdown-cont')
    if (isOpen) {
      cont.classList.add(`active`)
    } else {
      cont.classList.remove(`active`)
    }
    setIsOpen(isOpen)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // clean up on unmount
    return function cleanup () {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])
  const activeObj = items.find(item => item.slug === active)

  activeObj.selected = 'selected'
  return (
    <div className='dropdown-cont' css={contCss} {...rest}>
      <Libs.Atoms.Span
        ref={activatorRef}
        className='activator'
        onClick={handleClick}
        styles={styles}
      >
        {activeObj.text}
      </Libs.Atoms.Span>
      <div ref={listRef} className={`itemList ` + (isOpen ? 'active' : null)}>
        {items.map((item, index) => {
          const classNameStr = item.slug === active ? 'selected' : ''

          return (
            <div key={index} className={'item'}>
              {trigerLink ? (
                <Libs.Atoms.TriggerLink
                  to={item.url}
                  className={classNameStr}
                  onClick={event => linkHandler(event, item)}
                  data-key={item.slug}
                >
                  <Libs.Atoms.Span className='span-element' styles={styles}>
                    {item.text}
                  </Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
              ) : (
                <a href={item.url}>
                  <Libs.Atoms.Span className={classNameStr} styles={styles}>
                    {item.text}
                  </Libs.Atoms.Span>
                </a>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Dropdown
