import React from 'react'
import { Global, css } from '@emotion/react'
import * as LibFont from 'libs/styles/fontface'
import * as Font from './fontface'
import * as Libs from 'libs'
export const StoryStyle = props => {
  const gradientStanp = props => {
    return css`
      position: relative;
      display: block !important;
      svg {
        margin-top: 0px;
      }
      a {
        position: relative;
        white-space: nowrap !important;
        display: inline-flex !important;
        align-items: center;
        padding: 5px 13px 7px 8px;
        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          z-index: 100;
          line-height: normal !important;
        }
        &:before {
          content: ' ';
          position: absolute;
          background-color: #fff;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          left: 0px;
          z-index: 0;
        }
      }
    `
  }
  return (
    <Global
      styles={css`
        body {
          background-color: #fff;
        }
        .project-container {
          .on-menu & {
            pointer-events: none;
          }
        }

        ${Font.FontFace()}
        .scroll-content {
          height: 100vh;
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: none !important;
        }
        .grid-cont {
          img {
            height: auto;
            object-fit: contain;
            width: 100%;
            max-height: 100%;
          }
        }
        .rt-cont-root > .rt-inner {
          > * {
            ${Libs.Common.Func.getPcSpVwValue('margin-top', 100, 60, true)};
          }
        }
        html[lang='en'] {
          .rt-cont,
          .rt-cont.for-article,
          .rt-cont.for-full-article {
            .rt-inner {
              > h1.rt-embeded,
              > h1.rt-content,
              > h2.rt-content,
              > h3.rt-content {
                ${Libs.Styles.Font.Bold()}
              }
              > h1.rt-embeded,
              > h1.rt-content {
                ${Libs.Common.Func.getPcSpVwValue('font-size', 54, 34, true)};
                ${Libs.Common.Func.getPcSpVwValue('line-height', 54, 34, true)};
                ${Libs.Common.Func.getPcSpVwValue(
                  'letter-spacing',
                  -1.5,
                  -0.5,
                  true
                )};
              }
              > h2.rt-embeded,
              > h2.rt-content {
                ${Libs.Common.Func.getPcSpVwValue('font-size', 36, 26, true)};
                ${Libs.Common.Func.getPcSpVwValue('line-height', 36, 26, true)};
                ${Libs.Common.Func.getPcSpVwValue(
                  'letter-spacing',
                  -0.75,
                  -0.5,
                  true
                )};
              }
              > h3.rt-embeded,
              > h3.rt-content {
                ${Libs.Common.Func.getPcSpVwValue('font-size', 28, 22, true)};
                ${Libs.Common.Func.getPcSpVwValue(
                  'letter-spacing',
                  -0.75,
                  -0.5,
                  true
                )};
                ${Libs.Common.Func.getPcSpVwValue('line-height', 28, 22, true)};
              }
              > .default-text.rt-content {
                ${Libs.Common.Func.getPcSpVwValue(
                  'font-size',
                  18,
                  16,
                  true,
                  2,
                  0
                )};
                ${Libs.Common.Func.getPcSpVwValue(
                  'line-height',
                  28,
                  24,
                  true,
                  2,
                  0
                )};
              }

              > .small-text.rt-content {
                ${Libs.Common.Func.getPcSpVwValue(
                  'font-size',
                  14,
                  12,
                  true,
                  1,
                  1
                )};
                ${Libs.Common.Func.getPcSpVwValue(
                  'line-height',
                  20,
                  20,
                  true,
                  3,
                  1
                )};
              }
            }
          }
        }
        html[lang='ja'] {
          h1,
          h2,
          h3,
          h4 {
            font-weight: 500;
          }
          .rt-cont,
          .rt-cont.for-article,
          .rt-cont.for-full-article {
            .rt-inner {
              > h1.rt-embeded,
              > h1.rt-content,
              > h2.rt-content,
              > h3.rt-content,
              > h4.rt-content {
                ${Libs.Styles.Font.Bold()}

                letter-spacing: -1.5px;
              }
              > h1.rt-embeded,
              > h1.rt-content {
                ${Libs.Common.Func.getPcSpVwValue('font-size', 54, 32, true)};
                ${Libs.Common.Func.getPcSpVwValue('line-height', 60, 36, true)};
              }
              > h2.rt-embeded,
              > h2.rt-content {
                ${Libs.Common.Func.getPcSpVwValue('font-size', 36, 26, true)};
                ${Libs.Common.Func.getPcSpVwValue('line-height', 36, 26, true)};
              }
              > h3.rt-embeded,
              > h3.rt-content {
                ${Libs.Common.Func.getPcSpVwValue('font-size', 28, 22, true)};
                ${Libs.Common.Func.getPcSpVwValue(
                  'letter-spacing',
                  -0.75,
                  -0.5,
                  true
                )};
                ${Libs.Common.Func.getPcSpVwValue('line-height', 28, 22, true)};
              }
              > .default-text.rt-content {
                ${Libs.Common.Func.setPcVwValues('font-size', 16, true)};
                ${Libs.Common.Func.setPcVwValues('line-height', 26, true)};
                ${Libs.Common.Func.setSpVwValues('font-size', 15, true)};
                ${Libs.Common.Func.setSpVwValues('line-height', 24, true)};
                ${Libs.Common.Func.getPcSpVwValue(
                  'letter-spacing',
                  -0.05,
                  -0.05,
                  true
                )};
                ${Libs.Styles.Font.Bold()}
              }
              > .small-text.rt-content {
                ${Libs.Common.Func.setPcVwValues('font-size', 14, true)};
                ${Libs.Common.Func.setPcVwValues('line-height', 20, true)};
                ${Libs.Common.Func.setSpVwValues('font-size', 12, true)};
                ${Libs.Common.Func.setSpVwValues('line-height', 20, true)};
                font-weight: 500;
              }
            }
          }
        }
        .vertical .clone-image {
          img {
            ${Libs.Styles.Mq.moreTab} {
              object-fit: contain !important;
            }
          }
        }
        .index-feature-block {
          position: relative;
          ${Libs.Styles.Mq.moreTab} {
            height: 100vh;
            &:first-child {
              background-color: #0079aa;
              .rt-cont {
                margin-top: 30vh;
              }
            }
            &:nth-child(2) {
              background-color: #f4b2bb;
            }
          }
          ${Libs.Styles.Mq.lessPab} {
            height: auto;
            &:first-child {
              margin-top: 160px !important;
              .rt-cont {
                display: none;
              }
            }
            &:last-child {
            }
          }
          .rt-cont {
            > .rt-content:last-child {
              text-align: center;
            }
          }
        }
        .border-button {
          button {
            border-radius: 40px;
            padding: 10px 0;
            background-color: transparent;
            color: #163432;
            border: #163432 2px solid;
            line-height: 18px;
            span {
              font-size: 16px;
            }
          }
        }
        .index-cafe-block {
          .for-small-article {
          }
        }
        .link-icon-element {
          svg {
            vertical-align: middle;
            margin-right: 5px;
            margin-top: -3px;
          }
        }
        .round-background-white {
          background-color: #fff;
          color: #000;
          border-radius: 10px;
          padding: 10px;
          white-space: nowrap;
          display: inline-block !important;
        }
        .round-background-gray {
          background-color: #cccccc;
          color: #fff;
          border-radius: 10px;
          padding: 10px;
          white-space: nowrap;
          display: inline-block !important;
        }
        .red-yelow-whitebg-stamp {
          ${gradientStanp()}
          svg {
            fill: #e21000;
          }
          span {
            background-image: linear-gradient(to left, #f5c31c, orange, red);
          }
        }
        .violet-blue-whitebg-stamp {
          ${gradientStanp()}
          svg {
            fill: violet;
          }
          span {
            background-image: linear-gradient(to left, blue, indigo, violet);
          }
        }
        .rainbow-whitebg-stamp {
          ${gradientStanp()}
          span {
            background-image: linear-gradient(
              to left,
              violet,
              indigo,
              blue,
              green,
              yellow,
              orange,
              red
            );
          }
        }
        .block-bg-white {
          background-color: #fff;
          padding-top: 8px;
          padding-bottom: 10px;
          padding-left: 8px !important;
          padding-right: 8px !important;
        }
        .inline-bg-white {
          padding-left: 10px !important;
          padding-right: 10px !important;

          p {
            display: inline;
            background-color: #fff;
            box-shadow: 10px 0 0px 0px #fff, -10px 0 0px 0px #fff;
            padding-top: 1px;
            padding-bottom: 1px;
            color: black;
          }
        }
        .inline-bg-black {
          padding-left: 10px !important;
          padding-right: 10px !important;
          p {
            display: inline;
            background-color: #000;
            box-shadow: 10px 0 0px 0px #000, -10px 0 0px 0px #000;
            padding-top: 1px;
            padding-bottom: 1px;
            color: white;
          }
        }
        :root {
          --primary: #22d2a0;
          --secondary: #fff;
          --background: #fff;
          --green: #1fc11b;
          --yellow: #000;
          --orange: #ff9c55;
          --red: #ff5555;
        }
        .cards {
          background-color: var(--background);
          display: block;
          width: 300px;
          min-height: 90px;
          cursor: pointer;
          padding: 15px;
          margin: calc(50vh - 30px) auto 0 auto;
          border: 3px solid var(--primary);
          box-shadow: 10px -10px 0 -3px var(--yellow), 10px -10px var(--yellow),
            20px -20px 0 -3px var(--yellow), 20px -20px var(--yellow);
        }
        .video-inner-cont {
          line-height: 0px;
          background-color: #e2ddd6;
          .category-works & {
            background-color: transparent;
          }
          video {
            opacity: 0;
          }
        }
        .video-cont {
          video {
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
        }
      `}
    />
  )
}
