import React from 'react'
import { css } from '@emotion/react'

import * as Libs from 'libs'

export default props => {
  //--------------------------------------
  //  Styles
  //--------------------------------------
  const { content, ...rest } = props

  //--------------------------------------
  //  Css
  //--------------------------------------
  const gridContCss = () =>
    css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 350, 250, false)};
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      row-gap: 8rem;
      width: 92vw;
      margin-left: auto;
      margin-right: auto;
      ${Libs.Styles.Mq.moreTab} {
        column-gap: 2rem;
      }
      ${Libs.Styles.Mq.lessPab} {
        column-gap: 0.5rem;
      }
    `

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const rtContCss = () =>
    css`
      width: 92vw;
      max-width: 1600px;
      margin: 0 auto;
    `

  return (
    <section
      {...rest}
      data-categoryname={'Creation'}
      data-categoryslug={'creation'}
      data-type={'page'}
    >
      <section css={gridContCss}>
        {content ? (
          <Libs.Atoms.RichText css={rtContCss} className='rt-cont rt-cont-root'>
            {content}
          </Libs.Atoms.RichText>
        ) : (
          ''
        )}
      </section>
    </section>
  )
}
