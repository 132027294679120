import React from 'react'
import { css } from '@emotion/react'

import { StaticImage } from 'gatsby-plugin-image'
import * as Libs from 'libs'

export default props => {
  //--------------------------------------
  //  Css
  //--------------------------------------

  const listGridCss = () =>
    css`
      grid-column: 1 / -1;
      width: 100%;
      display: grid;

      grid-template-columns: 1fr 1fr 1fr 1fr;

      li {
        justify-self: center;
        align-self: center;
        img {
          object-fit: contain !important;
        }
      }
      ${Libs.Styles.Mq.moreTab} {
        columngap: 2rem;
        rowgap: 4rem;
        li {
          padding: 5vw 7vw;
          img {
            max-height: 120px;
          }
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        columngap: 1rem;
        rowgap: 2rem;
        li {
          padding: 4vw 5vw;
          img {
            max-height: 55px;
          }
        }
      }
    `

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const { className, ...rest } = props

  return (
    <ul css={listGridCss}>
      <li>
        <StaticImage src='../../images/logo-nike.png' alt='Nike' />
      </li>
      <li>
        <StaticImage src='../../images/logo-adidas.png' alt='adidas' />
      </li>
      <li>
        <StaticImage src='../../images/logo-smirnoff.png' alt='smirnoff' />
      </li>
      <li>
        <StaticImage src='../../images/logo-wwf.png' alt='wwf' />
      </li>
      <li>
        <StaticImage src='../../images/logo-mcdonalds.png' alt='mcdonalds' />
      </li>
      <li>
        <StaticImage src='../../images/logo-coleman.png' alt='coleman' />
      </li>
      <li>
        <StaticImage src='../../images/logo-citroen.png' alt='citroen' />
      </li>
      <li>
        <StaticImage src='../../images/logo-ford.png' alt='ford' />
      </li>
      <li>
        <StaticImage src='../../images/logo-lark.png' alt='lark' />
      </li>
      <li>
        <StaticImage src='../../images/logo-marlboro.png' alt='marlboro' />
      </li>
      <li>
        <StaticImage src='../../images/logo-hitachi.png' alt='hitachi' />
      </li>
      <li>
        <StaticImage src='../../images/logo-docomo.png' alt='docomo' />
      </li>{' '}
      <li>
        <StaticImage src='../../images/logo-wacom.png' alt='wacom' />
      </li>
      <li>
        <StaticImage src='../../images/logo-pfizer.png' alt='pfizer' />
      </li>
      <li>
        <StaticImage src='../../images/logo-nintendo.png' alt='nintendo' />
      </li>
    </ul>
  )
}
