import React, { Component } from "react";
import { css } from "@emotion/react";
import * as Styles from "../../libs/styles";
class Symbol extends Component {
	render() {
		return (
			<svg
				className={"symbol"}
				css={style}
				viewBox="0 0 159.52 55.18"
				preserveAspectRatio="xMinYMin meet"
			>
				<use xlinkHref="#symbol" x="0" y="0" />
			</svg>
		);
	}
}
const style = () => css`
	z-index: 10000;
	fill: #000;
	height: 45px;
	margin-top: -14px;
	${Styles.Mq.large} {
		height: 52px;
	}
	${Styles.Mq.pc} {
		height: 45px;
		margin-top: -11px;
	}
	${Styles.Mq.tab} {
		height: 36px;
		margin-top: -7px;
	}
	${Styles.Mq.pab} {
		height: 36px;
		margin-top: -7px;
	}
	${Styles.Mq.sp} {
		height: 36px;
		margin-top: -7px;
	}
`;
export default Symbol;
