import React, { useEffect, useContext, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css } from '@emotion/react'
import * as Common from '../../common'
import * as Atoms from '../atoms'
import * as Modules from '.'
import StoreContext from 'libs/context/StoreContext'
import * as Action from 'libs/redux/action'

import { gsap, Power3 } from 'gsap'
import * as Libs from 'libs'

export default props => {
  const { styles, ...rest } = props
  const { restoreCheckout } = useContext(StoreContext)
  const langKey = Libs.Common.Func.getLangKey()
  const [lang, setLang] = useState(langKey)
  const dispatch = useDispatch()
  let contentScrollTop = 0
  let langInfo = Libs.Common.Func.getLangInfo()
  let menuState = useSelector(state => {
    return state.ClickReducer.onMenu
  })
  const tl = gsap.timeline()

  useEffect(() => {
    if (menuState) {
      appear()
    } else {
    }
  }, [menuState])

  //--------------------------------------
  //  Styles
  //--------------------------------------

  const navStyles = {
    fontPc: {
      fontSize: 64,
      bold: true,
      letterSpacing: '-.5'
    },
    fontSp: {
      fontSize: 48,
      bold: true,
      letterSpacing: '-.5'
    }
  }

  //--------------------------------------
  //  Css
  //--------------------------------------
  const gnavCss = () => {
    return css`
      opacity: 1;
      position: fixed;
      z-index: 1000;
      top: 0px;
      left: 0px;
      z-index: 300;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      .on-menu & {
        pointer-events: auto;
      }
      .gnav-mb {
        position: relative;
        z-index: 2;

        ul {
          li {
            cursor: pointer;
            overflow: hidden;
            span {
              display: inline-block;
              transform: translateY(-120%);
            }
          }
        }
        .activator {
          ${Libs.Styles.Mq.moreTab} {
            max-width: 10.5vw;
          }
          ${Libs.Styles.Mq.lessPab} {
            max-width: 23vw;
          }
          &:after {
            border-bottom: 5px solid #000;
            border-right: 5px solid #000;
            margin-top: -20px;
          }
        }
      }
      ${Libs.Styles.Mq.moreTab} {
        .gnav-mb {
          transform: translateY(-75%);
          top: 50%;
          margin-left: 10vw;
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        .gnav-mb {
          top: 15px;
          margin-left: 15px;
        }
      }
    `
  }
  const closeButtonCss = () => {
    return css`
      position: absolute;
      z-index: 10;

      cursor: pointer;
      top: 25px;
      right: 25px;
      ${Libs.Styles.Mq.lessPab} {
        top: 12px;
        right: 12px;
      }
    `
  }

  const bgCss = () => {
    return css`
      width: 100vw;
      height: 0vh;
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: #65756b;
    `
  }
  const footerCss = () => {
    return css`
      margin: 0 auto;
      position: fixed;
      bottom: 0px;
      z-index: 10;
      overflow: hidden;
      ${Libs.Styles.Mq.morePc} {
        width: 92vw;
        left: 4vw;
        padding-bottom: 2rem;
      }
      ${Libs.Styles.Mq.tb} {
        width: 94vw;
        padding-bottom: 2rem;
      }
      ${Libs.Styles.Mq.lessPab} {
        width: 90vw;
        left: 5vw;
        padding-bottom: 1.5rem;
      }
    `
  }
  const containerCss = () => {
    //background-color: #f0F;
    return css`
      transform: translateY(-120%);
      column-gap: var(--grid-gap);
      row-gap: var(--grid-gap);
      ${Libs.Styles.Mq.morePc} {
        display: grid;
        --grid-gap: 2rem;
        --grid-margin: 4rem;
        grid-template-columns: 3fr 1fr 1fr;
      }
      ${Libs.Styles.Mq.tb} {
        display: grid;
        --grid-gap: 2rem;
        --grid-margin: 4rem;
        grid-template-columns: 3fr 1fr 1fr;
      }
      ${Libs.Styles.Mq.lessPab} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        --grid-gap: 1rem;
        --grid-margin: 1.5rem;
        .address-cont {
          display: none;
        }
      }

      .info-lw-address {
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 1 / -1;
          grid-row: 2 / 3;
          margin-bottom: 1rem;
        }
      }
      .info-howlt {
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 1 / 2;
          grid-row: 4 / 5;
        }
      }
      .info-hc {
        ${Libs.Styles.Mq.moreTab} {
          justify-self: right;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 2 / 3;
          grid-row: 4 / 5;
          justify-self: right;
          .hc-address {
            display: none;
          }
        }
      }
    `
  }

  const loworksLogo = () => css`
    svg {
      height: 18px;
    }
    ${Libs.Styles.Mq.moreTab} {
    }
    ${Libs.Styles.Mq.lessPab} {
      grid-column: 1 / -1;
      grid-row: 1 / 2;

      svg {
        height: 13px;
      }
    }
  `
  const HowltLogo = () => css`
    svg {
      height: 18px;
    }
    ${Libs.Styles.Mq.moreTab} {
    }
    ${Libs.Styles.Mq.lessPab} {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
      svg {
        height: 13px;
      }
    }
  `
  const HcLogo = () => css`
    svg {
      height: 18px;
    }
    ${Libs.Styles.Mq.moreTab} {
      justify-self: right;
    }
    ${Libs.Styles.Mq.lessPab} {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      justify-self: right;
      svg {
        height: 13px;
      }
    }
  `
  const dropdownCss = () => css`
    overflow: hidden;
    .on-menu.on-menu-complete & {
      overflow: visible;
    }
    span {
      transform: translateY(-120%);
    }
  `

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const clickHandler = (event, id) => {
    const key = event.currentTarget.dataset.key
    const langKey = Libs.Common.Func.getLangKey()
    if (langKey === key) return
    Libs.Common.Config.setCurrentLang(key)
    setLang(key)
    restoreCheckout()
    dispatch(Action.LangChanged(key))
  }

  const appear = () => {
    const tl = gsap.timeline()
    const target = document.querySelector('.scroll-content')
    let isMobile = false
    if (Libs.Common.Func.getDeviceSize() === 'lessPab') {
      isMobile = true
    }

    if (!isMobile) contentScrollTop = target.getBoundingClientRect().top

    tl.to('.gnav-ov-bg', 0.6, {
      height: '100%',
      ease: Power3.easeOut,
      onComplete: function () {
        const className = 'on-menu-complete'
        const cont = document.getElementsByTagName('html')[0]
        cont.classList.add(className)
      }
    })
    tl.to(
      '.gnav-mb li span, .dropdown-lang-mb span, .ov-footer nav',
      0.6,
      { y: '0%', ease: Power3.easeOut },
      '-=.2'
    )
  }
  const hide = () => {
    tl.to('.gnav-mb li span, .dropdown-lang-mb span, .ov-footer nav', 0.6, {
      y: '-120%',
      ease: Power3.easeOut
    })
    tl.to(
      '.gnav-ov-bg',
      0.6,
      {
        height: '0%',
        ease: Power3.easeOut,
        onComplete: function () {
          const className = 'on-menu-complete'
          const cont = document.getElementsByTagName('html')[0]
          cont.classList.remove(className)
        }
      },
      '-=.45'
    )
    dispatch(Action.MenuClick(null, false))

    let isMobile = false
    if (Libs.Common.Func.getDeviceSize() === 'lessPab') {
      isMobile = true
    }
    if (!isMobile) {
      const scrollbar = window.Scrollbar
      scrollbar.scrollTop = Math.abs(contentScrollTop)
    }

    //
  }

  //--------------------------------------
  //  Code
  //--------------------------------------

  return (
    <div css={gnavCss}>
      <div className='gnav-mb'>
        <Modules.GlobalNavigation onClick={hide} styles={navStyles} />
        <Atoms.Dropdown
          active={lang}
          styles={navStyles}
          css={dropdownCss}
          onMenuClick={hide}
          className='dropdown-lang-mb'
          items={[
            {
              text: 'JA',
              slug: 'ja',
              url: langInfo[0].link,
              onClick: clickHandler
            },
            {
              text: 'EN',
              slug: 'en',
              url: langInfo[1] ? langInfo[1].link : '/',
              onClick: clickHandler
            }
          ]}
          trigerLink={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
        />
      </div>
      <Libs.Atoms.ButtonClose
        css={closeButtonCss}
        activeClass={'on-menu'}
        clickHandler={hide}
        iconSize='80px'
        rectSize='4px'
      />
      <footer className='ov-footer' css={footerCss}>
        <nav css={containerCss} className='footer'>
          <Atoms.InfoLwAddress></Atoms.InfoLwAddress>
          <Atoms.InfoHowlt></Atoms.InfoHowlt>
          <Atoms.InfoHc></Atoms.InfoHc>
          <h2 css={loworksLogo}>
            <Atoms.Logo />
          </h2>
          <h2 css={HowltLogo}>
            <Atoms.LogoHowlt />
          </h2>
          <h2 css={HcLogo}>
            <Atoms.Logo02 />
          </h2>
        </nav>
      </footer>
      <div className='gnav-ov-bg' css={bgCss}></div>
    </div>
  )
}
