import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/react'
import * as Styles from '../styles'
export default props => {
  const { clickHandler, forwardRef, id, ...rest } = props
  const volumeBtn = useRef(null)
  const style = () => css`
    background-color: rgba(37, 37, 37, 0.6);
    border-radius: 15px;
    display: inline-block;
    cursor: pointer;
    position: relative;

    .volume-on-btn {
      visibility: hidden;
    }
    .volume-off-btn {
      visibility: visible;
    }
    &.volume-on {
      .volume-on-btn {
        visibility: visible;
      }
      .volume-off-btn {
        visibility: hidden;
      }
    }
    ${Styles.Mq.morePc} {
      width: 26px;
      height: 26px;
    }
    ${Styles.Mq.tb} {
      width: 24px;
      height: 24px;
    }
    ${Styles.Mq.lessPab} {
      width: 22px;
      height: 22px;
    }
    svg {
      position: absolute;

      fill: #fff;
      ${Styles.Mq.morePc} {
        height: 24px;
        padding: 5px;
        margin-top: 1px;
        margin-left: 1px;
      }
      ${Styles.Mq.tb} {
        height: 20px;
        padding: 4px;
        margin-top: 2px;
        margin-left: 2px;
      }
      ${Styles.Mq.lessPab} {
        padding: 4px;
        height: 20px;
        margin-top: 1px;
        margin-left: 1px;
      }
    }
  `

  return (
    <div
      ref={volumeBtn}
      css={style}
      className='button-volume'
      {...rest}
      onClick={event => {
        volumeBtn.current.classList.toggle('volume-on')

        if (clickHandler) clickHandler()
        event.stopPropagation()

        return false
      }}
    >
      <svg
        className='volume-on-btn'
        viewBox='0 0 20 20'
        preserveAspectRatio='xMinYMin meet'
      >
        <use xlinkHref='#icon-volume' x='0' y='0' />
      </svg>

      <svg
        className='volume-off-btn'
        viewBox='0 0 20 20'
        preserveAspectRatio='xMinYMin meet'
      >
        <use xlinkHref='#icon-volumeoff' x='0' y='0' />
      </svg>
    </div>
  )
}
