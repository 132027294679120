import React, { useEffect, useRef, useState } from 'react'
import { css } from '@emotion/react'

import * as Modules from '../modules'

import * as Libs from 'libs'
import ScrollEase from 'libs/ScrollEase'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
//let imgLoadedList = []
//let isloaded = false

export default props => {
  const { feature, content, className, ...rest } = props
  const container = useRef()
  const ref = useRef(true)

  const [ready, setReady] = useState(false)

  let screenH
  if (typeof window !== 'undefined') {
    screenH = window.innerHeight
  }

  useEffect(() => {
    ScrollEase.WrappedComponent.container = container.current

    setReady(true)

    if (ref.current) {
      ref.current = false
      return
    }

    return () => {
      //imgLoadedList = []
      setReady(false)
    }
  }, [])

  //--------------------------------------
  //  Styles
  //--------------------------------------
  let data = feature

  const len = data.length

  const noImgData = []
  const imgData = []
  for (let i = 0; i < len; i++) {
    const value = data[i]
    if (!value.thumbnail) {
      noImgData.push(value)
    } else {
      imgData.push(value)
    }
  }

  const generateImageElement = item => {
    const image = item.thumbnail
    const imageSp = item.thumbnailSp
    const lang = Libs.Common.Func.getLangKey()
    const link = `/${item.category.slug}/${item.slug}`
    return {
      __typename: 'ContentfulElementImage',
      caption: null,
      class: null,
      image: image,
      imageSp: imageSp,
      link: link,
      internal: { type: 'ContentfulElementImage' },
      containerTag: null,
      stylesJson: null,
      width_pc: 'auto',
      width_sp: 'auto'
    }
  }

  const generateGridObject = () => {
    const gridImages =
      imgData &&
      imgData.map((item, i) => {
        return generateImageElement(item)
      })

    const gridElementObj = {
      __typename: 'ContentfulElementGrid',
      gridItems: gridImages,
      gridJson: gridLayoutObject,
      internal: { type: 'ContentfulElementGrid' },
      stylesJson: null,
      width_pc: null,
      width_sp: null
    }
    return gridElementObj
  }
  const imgContCss = prop => {
    console.log('citerm -- ', prop)
    return css`
      .video-inner-cont {
      }
    `
  }

  const getGridElement = content => {
    const gridItems = content.gridItems.map(citerm => {
      if (citerm.internal.type === 'ContentfulElementImage' && citerm.image) {
        return (
          <Libs.Atoms.ImageElement
            src={citerm.image.fluid}
            css={[imgContCss(citerm.image)]}
            styles={citerm}
            caption={citerm.caption}
            videoController={false}
            onload={() => {}}
          />
        )
      } else if (citerm.internal.type === 'ContentfulRtTextField') {
        return ''
      }
    })

    return (
      <Libs.Atoms.GridElement className={content.class} styles={content}>
        {gridItems}
      </Libs.Atoms.GridElement>
    )
  }
  /*
  const setMedialoaded = test => {
    imgLoadedList.push(true)
    if (imgLoadedList.length >= 5 && !isloaded) {
      isloaded = true
    }
  }
*/
  const gridLayoutObject = {
    pc: {
      //gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateColumns: 'repeat(12, 1fr)',
      columnGap: '1rem',
      rowGap: '4rem',
      gridColumns: [
        '1 / 7',
        '9 / 13',
        '1 / 4',
        '5 / 8',
        '8/ 13',
        '1 / 6 ',
        '7 / 10',
        '10 / 13',
        '1 / 4',
        '4 / 7',
        '7 / 10',
        '10 / 13'
      ],
      alignSelf: ['', '', '', '', '', '', '', '', 'end', 'end', 'end', 'end']
      //gridColumns: ['1 / 3', '4 / 5', '1 / 2', '2 /3', '3 / 5']
    },
    sp: {
      //gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateColumns: 'repeat(12, 1fr)',
      columnGap: '1rem',
      rowGap: '4rem',
      gridColumns: [
        '1 / 9',
        '9 / 13',
        '1 / 8',
        '8 / 13',
        '5/ 13',
        '1 / 9 ',
        '1 / 9',
        '9 / 13',
        '1 / 5',
        '5/ 13',
        '1 / 7',
        '7 / 13'
      ],
      gridRows: ['', '', '', '', '', '', '', '', '', ''],
      alignSelf: ['', '', '', '', '', '', '', '', 'end', 'end', 'end', 'end']

      //gridColumns: ['1 / 3', '4 / 5', '1 / 2', '2 /3', '3 / 5']
    }
  }

  //--------------------------------------
  //  Scripts
  //--------------------------------------

  const rtContCss = () => {
    return css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 120, 100, true)};
      margin-left: auto;
      margin-right: auto;
      width: 96vw;
      ${Libs.Styles.Mq.lessPab} {
        width: 90vw;
      }
    `
  }
  const workSectionCss = () => {
    return css`
      margin: 0 auto;
      padding-bottom: 5vh;
      ${Libs.Styles.Mq.morePc} {
        width: 96vw;
        ${Libs.Common.Func.getPcSpVwValue('margin-top', 450, 250, false)};
      }
      ${Libs.Styles.Mq.tb} {
        width: 92vw;
        margin-top: 300px;
      }
      ${Libs.Styles.Mq.lessPab} {
        width: 90vw;
        padding-top: 400px;
      }
    `
  }

  /*if (!ready || isloaded) {
   return
  }*/

  return (
    <>
      <section
        className={className}
        ref={container}
        {...rest}
        data-categoryname={'Index'}
        data-categoryslug={'index'}
        data-type={'page'}
      >
        <section css={workSectionCss}>
          {getGridElement(generateGridObject())}
        </section>
        {content ? (
          <Libs.Atoms.RichText css={rtContCss} className='rt-cont'>
            {content}
          </Libs.Atoms.RichText>
        ) : (
          ''
        )}

        {/*<section css={sectionCss}>
          <Libs.Modules.BodyElement
            content={content}
            layoutTypeFunc={Common.Func.getCardType}
            elementTypeFunc={Common.Func.getElementType}
          />
        </section>
        <Modules.IndexShop />*/}
      </section>
    </>
  )
}
