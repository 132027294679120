import React, { PureComponent } from 'react'
import { combineReducers } from 'redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import LibReducers from 'libs/redux/reducers/index'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import * as Style from '../styles'
import * as Libs from 'libs'

import * as Common from '../common'
import * as Func from '../libs/common/func'
import * as Modules from '../components/modules'
import Header from '../components/organisms/Header'
import Footer from '../components/organisms/Footer'
import * as Transition from '../components/Transition'
import WindowResize from 'libs/redux/event/WindowResize'

import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n'
import { IntlProvider, addLocaleData } from 'react-intl'
import ContextProvider from '../provider/ContextProvider'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Scrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'

const Container = styled.div`
  align-items: center;
  justify-content: center;
`
const combineReducer = combineReducers(Object.assign(LibReducers))

const store = createStore(combineReducer)
const style = () => {
  return css`
    opacity: 0;
  `
}
Libs.Common.Config.setWebsiteURL(Common.Config.websiteUrl)
Libs.Common.Config.setClassList([
  'type-category',
  'type-page',
  'type-post',
  'category-works',
  'category-shop',
  'category-index',
  'category-magazine',
  'category-about',
  'category-contact',
  'category-howltcoffee'
])

let current = 'Index'
class Layout extends PureComponent {
  static get current () {
    return current
  }
  static set current (value) {
    current = value
  }
  constructor (props) {
    super(props)
    this.scrollerRef = React.createRef()
    this.container = React.createRef()

    if (typeof window !== `undefined`) {
      const location = window.location
      const url = location.pathname
      Libs.Common.Config.setLangInfo(['ja', 'en'], 'ja')
      const { langs, defaultLangKey } = Libs.Common.Config.languages
      this.langKey = getCurrentLangKey(langs, defaultLangKey, url)
      this.homeLink = `/${this.langKey}/`

      this.langsMenu = Func.getLangInfo()

      Libs.Common.Config.setDispatcher(store.dispatch)
      Libs.Common.Config.setInitTransition(
        Transition.InitTransition.InitTransition
      )
    }
  }
  componentDidMount () {
    const target = document.querySelector('.tl-wrapper')
    const isMob = Libs.Common.Config.isTouchDevice()

    let scrollbar = null
    gsap.registerPlugin(ScrollTrigger)

    if (!isMob) {
      const overscrollOptions = {
        enable: true,
        effect: 'bounce',
        damping: 0.15,
        maxOverscroll: 150,
        glowColor: '#fff'
      }
      Scrollbar.use(OverscrollPlugin)

      scrollbar = Scrollbar.init(this.scrollerRef.current, {
        damping: 0.08,
        delegateTo: document,
        continuousScrolling: false,
        alwaysShowTracks: false,
        plugins: {
          overscroll: { ...overscrollOptions }
        }
      })
      window.Scrollbar = scrollbar
      ScrollTrigger.scrollerProxy(this.scrollerRef.current, {
        scrollTop (value) {
          if (arguments.length) {
            scrollbar.scrollTop = value
          }
          return scrollbar.scrollTop
        }
      })
      scrollbar.addListener(ScrollTrigger.update)

      ScrollTrigger.defaults({ scroller: this.scrollerRef.current })
      window.scroller = this.scrollerRef.current
      if (typeof window !== `undefined`) {
        //document.body.scrollTop = document.documentElement.scrollTop = 0
      }
      this.scrollContent = document.querySelector('.scroll-content')

      /*this.enterframe.setContainer(document.querySelector(".scroll-content"));
		this.enterframe.start();*/
    }

    target.setAttribute('style', 'visibility:visible')
    this.container.current.setAttribute('style', 'opacity:1')

    return () => {
      if (scrollbar) {
        scrollbar.destroy()
        scrollbar.removeListener(ScrollTrigger.update)
      }
    }
  }
  render () {
    const { children } = this.props

    return (
      <IntlProvider locale={this.langKey}>
        <ContextProvider>
          <Provider store={store}>
            <Container ref={this.container} css={style}>
              <Libs.Styles.Reset />
              <Libs.Styles.Global.GlobalStyle />
              <Style.SiteStyle.StoryStyle />
              <WindowResize />
              <Header />
              <Modules.GlobalNaviForOverlay />
              {/*<Modules.Intro />*/}
              {Libs.Common.Func.getDeviceSize() !== 'lessPab' ? (
                <div className={'scrollcont'} ref={this.scrollerRef}>
                  <Modules.GlobalNaviForHeader />
                  {children}
                  <Footer langs={this.langsMenu} />
                </div>
              ) : (
                <>
                  <div className={'scrollcont'} ref={this.scrollerRef}>
                    <Modules.GlobalNaviForHeader />
                    {children}
                    <Footer langs={this.langsMenu} />
                  </div>
                </>
              )}
              <Modules.Loader />
              <Libs.Atoms.Overlay />
            </Container>
          </Provider>
        </ContextProvider>
      </IntlProvider>
    )
  }
}

export default Layout
