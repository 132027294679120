import React, { useEffect, useState } from 'react'

import * as Modules from '../modules'
import * as Func from '../../libs/common/func'
import * as Transition from '../Transition'

import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { WindowResize } from 'libs/redux/event/WindowResize'
import * as Libs from 'libs'

export default props => {
  //--------------------------------------
  //  Constructor
  //--------------------------------------

  //--------------------------------------
  //  Render
  //--------------------------------------

  const { items, category, type } = props

  const getListAjust = props => {
    if (type !== 'category') {
      return css`
        &:last-child {
          display: none;
        }
      `
    }
  }
  //--------------------------------------
  //  Css
  //--------------------------------------
  const filterCss = props => {
    return css`
      position: absolute;
      z-index: 100;
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 450, 250, false)};
    `
  }

  const contCss = props => {
    return css`
      ${Libs.Common.Func.getPcSpVwValue('padding-top', 530, 320, false)};
      display: grid;

      margin-left: auto;
      margin-right: auto;
      position: relative;
      width: 92vw;
      ${Libs.Styles.Mq.morePc} {
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
      }
      ${Libs.Styles.Mq.lessTab} {
        grid-template-columns: 1fr;
        column-gap: 1rem;
      }
    `
  }
  const itemCont = props => {
    return css`
      a {
        display: grid;
        grid-template-columns: 5fr 1fr;
        column-gap: 2rem;
      }
      position: relative;
      padding: 40px 0 25px 0;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #ccc;
        position: absolute;
        bottom: 0px;
      }
    `
  }

  const descCont = props => {
    return css``
  }

  const categoryContCss = props => {
    return css`
      margin-top: 2px;
      a {
        display: inline-block;
        &:last-child {
          p {
            &::after {
              content: '';
              margin-right: 0px;
            }
          }
        }
        p {
          &::after {
            content: ', ';
            margin-right: 5px;
          }
        }
      }
    `
  }

  //--------------------------------------
  //  Styles
  //--------------------------------------

  const pStyles = {
    fontPc: {
      fontSize: 12,
      lineHeight: 12,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    },
    fontSp: {
      fontSize: 12,
      lineHeight: 12,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    }
  }
  const h4Styles = {
    fontPc: {
      fontSize: 32,
      lineHeight: 32,
      fontFace: 'sansserif',
      bold: true,
      letterSpacing: '-.5'
    },
    fontSp: {
      fontSize: 24,
      lineHeight: 26,
      fontFace: 'sansserif',
      bold: true,
      letterSpacing: '-.5'
    }
  }

  //--------------------------------------
  //  Function
  //--------------------------------------

  if (!items) {
  } else {
    items.sort(function (a, b) {
      a = new Date(a.date)
      b = new Date(b.date)
      return a > b ? -1 : a < b ? 1 : 0
    })
  }
  if (WindowResize.currentType(WindowResize.morePc)) {
    const len = 5 - (items.length % 5)

    if (len < 5) {
      for (let i = 0; i < len; i++) {
        items.push({ name: 'empty' })
      }
    }
  }
  //--------------------------------------
  //  Code
  //--------------------------------------

  return (
    <>
      <Modules.TagFilter siteCategory={{ slug: 'journal' }} css={filterCss} />
      <div css={contCss}>
        {items &&
          items.map((item, i) => {
            if (item.name === 'empty') {
              return <div className='empty'></div>
            } else {
              const imgSrc =
                WindowResize.currentType(WindowResize.lessPab) &&
                item.thumbnailSp
                  ? item.thumbnailSp
                  : item.thumbnail
              const aspectRatio =
                imgSrc && imgSrc.gatsbyImageData
                  ? Math.floor(
                      (imgSrc.gatsbyImageData.width /
                        imgSrc.gatsbyImageData.height) *
                        Math.pow(10, 2)
                    ) / Math.pow(10, 2)
                  : 1
              const aspectClass = aspectRatio < 1 ? 'vertical' : ''

              return (
                <article css={itemCont} className={`flex-item ${item.slug}`}>
                  <Libs.Atoms.TriggerLink
                    to={`/${item.node_locale}/${item.category.slug}/${item.slug}/`}
                    transition={
                      Libs.Transition.TrigerPageTransition.TrigerPageTransition
                    }
                  >
                    <div css={descCont} className={'text-element'}>
                      <div>
                        <Libs.Atoms.H4 styles={h4Styles}>
                          {item.name}
                        </Libs.Atoms.H4>
                      </div>
                      <div css={categoryContCss}>
                        <div>
                          {item.tags
                            ? item.tags.map(props => {
                                if (props.group === 'Category') {
                                  return (
                                    <div>
                                      <Libs.Atoms.P styles={pStyles}>
                                        {props.name}
                                      </Libs.Atoms.P>
                                    </div>
                                  )
                                }
                              })
                            : ''}
                        </div>
                      </div>
                    </div>
                    {imgSrc && imgSrc.gatsbyImageData ? (
                      <div className={`img-cont img-element ${aspectClass}`}>
                        <GatsbyImage
                          image={imgSrc.gatsbyImageData}
                          alt={imgSrc.title}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </Libs.Atoms.TriggerLink>
                </article>
              )
            }
          })}
      </div>
    </>
  )
}
