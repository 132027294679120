import React from 'react'
import { css } from '@emotion/react'

import * as Libs from 'libs'
import * as Common from '../../common'

export default props => {
  //--------------------------------------
  //  Css
  //--------------------------------------

  const pStyles = {
    fontPc: {
      fontSize: 14,
      lineHeight: 14,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    },
    fontSp: {
      fontSize: 14,
      lineHeight: 14,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    }
  }

  //--------------------------------------
  //  Css
  //--------------------------------------

  const gridCont = () =>
    css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 24, 24, true)};
      li {
        width: 100%;
        display: grid;
        column-gap: 2rem;
        row-gap: 2rem;

        position: relative;
        &::after {
          content: '';
          background-color: #000;
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 0px;
        }

        ${Libs.Styles.Mq.moreTab} {
          grid-template-columns: 3fr 6fr 2fr 1fr;
          padding: 1rem 0 1.2rem 0;
          span {
            &:last-child {
              justify-self: right;
            }
          }

          row-gap: 2rem;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-template-columns: 1fr 1fr;
          row-gap: 0.5rem;
          padding: 0.75rem 0 1rem 0;
          span {
            &:nth-child(2) {
              grid-column: 1 / 2;
            }
            &:nth-child(3) {
              grid-column: 2 / 3;
              grid-row: 1 / 2;
              text-align: right;
              justify-self: right;
            }
            &:last-child {
              grid-column: 2 / 3;
              justify-self: right;
            }
          }
        }
      }
    `

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const { ...rest } = props

  return (
    <div styles={pStyles} {...rest}>
      <Libs.Atoms.H2 styles={Common.Config.getH2Styles()}>
        Exhibition
      </Libs.Atoms.H2>
      <ul css={gridCont}>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Howlt Popup Store & Coffee
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Magasinn Kyoto</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Kyoto</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2019</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>HOWLT COFFEE</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            UltraSuperNew Gallery
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Tokyo</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2018</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>REPETITION</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            The Artcomplex Center
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Tokyo</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2017</Libs.Atoms.Span>
        </li>

        <li>
          <Libs.Atoms.Span styles={pStyles}>Gaya art</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}></Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Asagaya, Tokyo</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2015</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Graniph Japanese Designers project
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>
            graniph Harajuku Gallery
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Tokyo</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2009</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            Elshopo x LOWORKS Ehibition
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}></Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Koenji Tokyo</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2008</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>JPG2 EXhibition</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>RAS GalleryDoctor</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>Barcelona Spain</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2006</Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyles}>
            The new apartment Exhibition
          </Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}></Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>New York</Libs.Atoms.Span>
          <Libs.Atoms.Span styles={pStyles}>2006</Libs.Atoms.Span>
        </li>
      </ul>
    </div>
  )
}
