import { css } from '@emotion/react'
import * as Libs from 'libs'

import HowltSansWoff from '../font/howlt-sans-reg.woff2'
import HowltSansTtf from '../font/howlt-sans-reg.eot'
import HowltSansBoldWoff from '../font/howltsans-b.woff2'
import HowltSansBoldEot from '../font/howltsans-b.eot'
import HowltJPOtf from '../font/howltjp.woff2'

export const SanSerif = props =>
  css`
    font-family: Howlt, HowltJP, '游ゴシック', 'Yu Gothic', YuGothic,
      Hiragino Sans, Hiragino Kaku Gothic ProN, 'ヒラギノ角ゴ ProN W1',
      'Hiragino Kaku Gothic ProN W1', 'Hiragino Kaku Gothic Pro', 'メイリオ',
      Meiryo, Osaka, sans-serif;
  `

export const Bold = props =>
  css`
    font-family: Twm, Howlt, HowltJP, '游ゴシック', 'Yu Gothic', YuGothic,
      Hiragino Sans, 'ヒラギノ角ゴシック', Hiragino Kaku Gothic ProN,
      'ヒラギノ角ゴ ProN W1', 'Hiragino Kaku Gothic ProN W1',
      'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, sans-serif;
    font-weight: 900;
  `

export const FontFace = props => {
  Libs.Styles.Font.setSanSerifFont('Howlt')
  Libs.Styles.Font.setBoldFont("'Twm', 'Noto Sans JP'")

  return css`
    @font-face {
      font-family: 'Twm';
      src: url(${HowltSansBoldWoff}) format('woff2'),
        url(${HowltSansBoldEot}) format('embedded-opentype');
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      unicode-range: U + 000d-FB04;
    }
    @font-face {
      font-family: 'Howlt';
      src: url(${HowltSansWoff}) format('woff2'),
        url(${HowltSansTtf}) format('embedded-opentype');
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      unicode-range: U + 000d-FB04;
    }
    @font-face {
      font-family: 'HowltJP';
      src: url(${HowltJPOtf}) format('woff2');
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      unicode-range: U + 000d-FB04;
    }
  `
}
