import React, { PureComponent } from 'react'
import { css } from '@emotion/react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
class Image extends PureComponent {
  imgCss = () => {
    const ratio = this.state.ratio
    return css`
      > div {
        ${ratio ? { 'padding-bottom': ratio + '% !important' } : ''}
      }
    `
  }
  setRatio = value => {
    this.setState({ ratio: value })
    this.setState({ ratio: value })
    // this.state.ratio = value
  }
  getRatio = () => {
    return this.state.ratio === 0 ? this.ratio : this.state.ratio
  }
  constructor (props) {
    super(props)
    this.ratio = 0
    this.state = { ratio: 0 }
  }
  componentDidMount () {
    this.setRatio(this.ratio)
  }

  render () {
    const props = this.props
    const { ...rest } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            images: allFile {
              edges {
                node {
                  relativePath
                  name
                  childImageSharp {
                    gatsbyImageData(width: 2400, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const image = data.images.edges.find(n => {
            return n.node.relativePath.includes(props.src)
          })
          if (!image) {
            return null
          }

          const fluid = image.node.childImageSharp.gatsbyImageData
          this.ratio = (1 / fluid.aspectRatio) * 100
          this.setRatio(this.ratio)

          return (
            <GatsbyImage
              css={this.imgCss}
              alt={props.alt}
              image={fluid}
              {...rest}
            />
          )
        }}
      />
    )
  }
}

export default Image
