import React, { useEffect, useState, useRef } from 'react'

import * as Modules from '../modules'

import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { WindowResize } from 'libs/redux/event/WindowResize'
import * as Libs from 'libs'

export default props => {
  //--------------------------------------
  //  Constructor
  //--------------------------------------
  const videoEl = useRef(null)

  let isloaded = false
  useEffect(() => {
    return () => {}
  }, [])

  let screenW
  if (typeof window !== 'undefined') {
    screenW = window.innerWidth
  }

  //--------------------------------------
  //  Render
  //--------------------------------------

  const { items, category, type } = props

  const getListAjust = props => {
    if (type !== 'category') {
      return css`
        &:last-child {
          display: none;
        }
      `
    }
  }
  //--------------------------------------
  //  Css
  //--------------------------------------
  const filterCss = props => {
    return css`
      position: absolute;
      z-index: 100;
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 450, 250, true)};
    `
  }

  const contCss = props => {
    const { slug } = props
    const spWidth = slug === 'journal' ? 44 : 100
    const pcWidth = slug === 'journal' ? 30.4 : 28.4
    const largeWidth = slug === 'journal' ? 22.4 : 23.5
    return css`
      ${Libs.Common.Func.getPcSpVwValue('padding-top', 550, 380, true)};
      ${Libs.Styles.Flex.flexList({
        sp: spWidth,
        pab: 44,
        tb: 22.4,
        pc: pcWidth,
        large: largeWidth,
        xlarge: 18,
        between: true,
        wrap: true
      })}

      margin-left: auto;
      margin-right: auto;
      position: relative;
      ${Libs.Styles.Mq.moreTab} {
        width: 92vw;
      }
      ${Libs.Styles.Mq.lessPab} {
        width: 80vw;
      }
      .flex-item {
        ${Libs.Styles.Spacer.Margin({ bottom: '60px' })}
        ${Libs.Styles.Mq.lessPc} {
          ${getListAjust()}
          ${Libs.Styles.Spacer.Margin({ bottom: '60px' })}
        }
      }
    `
  }

  const descCont = props => {
    return css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 30, 30, true)};
    `
  }
  const descGridCont = props => {
    return css`
      display: grid;
      grid-template-columns: 6vw 1fr;
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      ${Libs.Styles.Mq.xlarge} {
        grid-template-columns: 100px 1fr;
      }
      ${Libs.Styles.Mq.pab} {
        grid-template-columns: 9vw 1fr;
      }
      ${Libs.Styles.Mq.sp} {
        grid-template-columns: 18vw 1fr;
      }
      > * {
        align-self: top;
        line-height: 0;
      }
      &::before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        ${Libs.Common.Func.getPcSpVwValue('top', 0, 0, true)};
      }
    `
  }
  const categoryContCss = props => {
    return css`
      a {
        display: inline-block;
        &:last-child {
          p {
            &::after {
              content: '';
              margin-right: 0px;
            }
          }
        }
        p {
          &::after {
            content: ', ';
            margin-right: 5px;
          }
        }
      }
    `
  }

  const imgLinkCss = props => {
    const spHeight = 80
    const pcHeight = 30
    const largeHeight = 23.5
    return css`
      ${Libs.Styles.Mq.tb} {
        height: 30vw;
      }
      ${Libs.Styles.Mq.pc} {
        height: ${pcHeight}vw;
      }
      ${Libs.Styles.Mq.large} {
        height: ${largeHeight}vw;
      }
      ${Libs.Styles.Mq.xlarge} {
        height: 20vw;
      }
      ${Libs.Styles.Mq.lessPab} {
        height: 38vw;
      }
      ${Libs.Styles.Mq.sp} {
        height: auto;
      }
    `
  }

  const imgCss = imgSrc => {
    const ratio =
      imgSrc && imgSrc.gatsbyImageData
        ? Math.floor(
            (imgSrc.gatsbyImageData.width / imgSrc.gatsbyImageData.height) *
              Math.pow(10, 2)
          ) / Math.pow(10, 2)
        : 1

    let w = (Math.sqrt(160 * ratio) / 16 / 0.9) * 100
    if (w > 100) {
      w = 100
    }

    const height = (screenW * 0.8 * w) / 100 / ratio

    return css`
      height: 100%;
      ${Libs.Styles.Mq.sp} {
        height: ${height}px;
      }
      position: relative;
      .gatsby-image-wrapper {
        position: absolute !important;
        top: auto;
        left: 50%;
        margin-left: ${-w / 2}%;
        bottom: 0px;
        width: ${w}%;
        height: auto;
      }
    `
  }
  //--------------------------------------
  //  Styles
  //--------------------------------------

  const pStyles = {
    fontPc: {
      fontSize: 12,
      lineHeight: 12,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    },
    fontSp: {
      fontSize: 12,
      lineHeight: 12,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    }
  }
  const h4Styles = {
    fontPc: {
      fontSize: 14,
      lineHeight: 14,
      bold: true,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    },
    fontSp: {
      fontSize: 14,
      bold: true,
      lineHeight: 14,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    }
  }

  //--------------------------------------
  //  Function
  //--------------------------------------

  if (!items) {
  } else {
    items.sort(function (a, b) {
      a = new Date(a.date)
      b = new Date(b.date)
      return a > b ? -1 : a < b ? 1 : 0
    })
  }
  if (WindowResize.currentType(WindowResize.morePc)) {
    const len = 5 - (items.length % 5)

    if (len < 5) {
      for (let i = 0; i < len; i++) {
        items.push({ name: 'empty' })
      }
    }
  }

  //--------------------------------------
  //  Code
  //--------------------------------------

  return (
    <>
      <Modules.TagFilter siteCategory={{ slug: 'works' }} css={filterCss} />
      <div css={contCss}>
        {items &&
          items.map((item, i) => {
            if (item.name === 'empty') {
              return <div className='empty'></div>
            } else if (item.slug.indexOf('dummy') !== -1) {
            } else {
              const imgSrc =
                WindowResize.currentType(WindowResize.lessPab) &&
                item.thumbnailSp
                  ? item.thumbnailSp
                  : item.thumbnail
              const aspectRatio =
                imgSrc && imgSrc.gatsbyImageData
                  ? Math.floor(
                      (imgSrc.gatsbyImageData.width /
                        imgSrc.gatsbyImageData.height) *
                        Math.pow(10, 2)
                    ) / Math.pow(10, 2)
                  : 1
              const aspectClass = aspectRatio < 1 ? 'vertical' : ''
              if (!imgSrc) {
                console.log('imgSrc.gatsbyImageData -- ', item.title)
              }

              const videoSrc =
                imgSrc && imgSrc.gatsbyImageData
                  ? null
                  : imgSrc && imgSrc.file
                  ? imgSrc.file.url
                  : null

              return (
                <article className={`flex-item ${item.slug}`}>
                  <Libs.Atoms.TriggerLink
                    to={`/${item.node_locale}/${item.category.slug}/${item.slug}/`}
                    transition={
                      Libs.Transition.TrigerPageTransition.TrigerPageTransition
                    }
                    css={imgLinkCss}
                  >
                    {videoSrc ? (
                      <>
                        <Modules.CategoryVideoElement
                          ratio={imgSrc.description}
                          videoSrc={videoSrc}
                        />
                      </>
                    ) : imgSrc && imgSrc.gatsbyImageData ? (
                      <div
                        css={imgCss(imgSrc)}
                        className={`img-cont img-element ${aspectClass}`}
                      >
                        <GatsbyImage
                          image={imgSrc.gatsbyImageData}
                          alt={imgSrc.title}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </Libs.Atoms.TriggerLink>
                  <div css={descCont} className={'text-element'}>
                    <div css={descGridCont}>
                      <Libs.Atoms.P styles={pStyles}>Category</Libs.Atoms.P>
                      <div css={categoryContCss}>
                        {item.tags
                          ? item.tags.map(props => {
                              if (props.group === 'Category') {
                                return (
                                  <Libs.Atoms.TriggerLink
                                    transition={
                                      Libs.Transition.TrigerPageTransition
                                        .TrigerPageTransition
                                    }
                                    to={`/${item.node_locale}/${item.category.slug}/${props.slug}/`}
                                  >
                                    <div>
                                      <Libs.Atoms.P styles={pStyles}>
                                        {props.name}
                                      </Libs.Atoms.P>
                                    </div>
                                  </Libs.Atoms.TriggerLink>
                                )
                              }
                            })
                          : ''}
                      </div>
                    </div>
                    <div css={descGridCont}>
                      <Libs.Atoms.P styles={pStyles}>Title</Libs.Atoms.P>
                      <Libs.Atoms.H4 styles={h4Styles}>
                        {item.headLine ? item.headLine[0].text : item.name}
                      </Libs.Atoms.H4>
                    </div>
                  </div>
                </article>
              )
            }
          })}
      </div>
    </>
  )
}
