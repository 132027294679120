import React, { useEffect, useContext, useState } from 'react'

import { css } from '@emotion/react'
import * as Common from '../../common'
import * as Atoms from '../atoms'
import * as Modules from '../modules'
import StoreContext from 'libs/context/StoreContext'
import * as Action from 'libs/redux/action'

import { useDispatch } from 'react-redux'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import * as Libs from 'libs'

const GlobalNaviForHeader = props => {
  useEffect(() => {
    if (
      Libs.Common.Func.getDeviceSize() !== 'lessTab' &&
      !Libs.Common.Config.isMobile()
    ) {
      ScrollTrigger.create({
        trigger: '.gnav',
        start: `top 25px`,
        end: 'top top-=200',
        pin: true,
        pinSpacing: false,
        markers: false
      })
      ScrollTrigger.create({
        trigger: '.scroll-content',
        start: `top+=400 0`,
        end: 'bottom+=100000 top',
        toggleClass: {
          targets: 'html',
          className: 'on-gnav-out'
        }
      })
      ScrollTrigger.create({
        trigger: '.scroll-content',
        start: `top+=400 0`,
        end: 'bottom+=100000 top',
        toggleClass: {
          targets: 'html',
          className: 'on-index-gnav-out'
        }
      })
    } else {
      ScrollTrigger.create({
        trigger: '.scroll-content',
        start: `top+=50 0`,
        end: 'bottom+=100000 top',
        toggleClass: {
          targets: 'html',
          className: 'on-gnav-out'
        }
      })
      ScrollTrigger.create({
        trigger: '.scroll-content',
        start: `top+=150 0`,
        end: 'bottom+=100000 top',
        toggleClass: {
          targets: 'html',
          className: 'on-index-gnav-out'
        }
      })
    }
  }, [])

  const { styles, ...rest } = props
  const { restoreCheckout } = useContext(StoreContext)

  const langKey = Libs.Common.Func.getLangKey()
  const [lang, setLang] = useState(langKey)
  const dispatch = useDispatch()
  let langInfo = Libs.Common.Func.getLangInfo()
  useEffect(() => {
    const langKey = Libs.Common.Func.getLangKey()
    setLang(langKey)
  }, [lang])

  const clickHandler = (event, id) => {
    const key = event.currentTarget.dataset.key
    const langKey = Libs.Common.Func.getLangKey()
    if (langKey === key) return
    Libs.Common.Config.setCurrentLang(key)
    setLang(key)
    restoreCheckout()
    dispatch(Action.LangChanged(key))
  }

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const gnavCss = () => {
    return css`
      opacity: 1;
      z-index: 300;
      ${Libs.Styles.Mq.moreTab} {
        top: 25px;
        position: absolute;
        transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        .type-page &,
        .type-category & {
          right: 4vw;
        }
        .category-index & {
          right: 2vw;
        }
        .type-post & {
          right: max(4vw, calc((100vw - 1600px) / 2));
        }
      }

      ${Libs.Styles.Mq.lessPab} {
        position: fixed;
        transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        .type-page &,
        .type-category & {
          top: 2rem;
          right: 4vw;
        }
        .dropdown-cont {
          overflow: hidden;
          &.active {
            overflow: visible;
          }
          > span {
            transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
            transform: translateY(0%);
          }
        }
        & li {
          overflow: hidden;
          span {
            transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
            transform: translateY(0%);
            display: inline-block;
          }
        }
        .category-creation.on-gnav-out &,
        .category-about.on-gnav-out &,
        .type-post.on-gnav-out &,
        .type-category.on-index-gnav-out &,
        .category-index.on-index-gnav-out & {
          span {
            transform: translateY(-120%);
          }
          pointer-events: none;
        }
        .type-post & {
          top: 5.5rem;
          right: 4vw;
        }
      }
    `
  }
  const navStyles = {
    fontPc: {
      fontSize: 22,
      bold: true,
      letterSpacing: '-.5',
      minRange: 2
    },
    fontSp: {
      fontSize: 18,
      bold: true,
      letterSpacing: '-.5'
    }
  }

  return (
    <div css={gnavCss}>
      <div className='gnav'>
        <Modules.GlobalNavigation styles={navStyles} />
        <Atoms.Dropdown
          active={lang}
          styles={navStyles}
          items={[
            {
              text: 'JA',
              slug: 'ja',
              url: langInfo[0].link,
              onClick: clickHandler
            },
            {
              text: 'EN',
              slug: 'en',
              url: langInfo[1] ? langInfo[1].link : '/',
              onClick: clickHandler
            }
          ]}
          trigerLink={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
        />
      </div>
    </div>
  )
}
export default GlobalNaviForHeader
