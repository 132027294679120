import React, { Component } from 'react'

import { css } from '@emotion/react'

import * as Atoms from '../../components/atoms'

import * as Modules from '../../components/modules'
import * as Common from '../../common'

import * as Libs from 'libs'

class Footer extends Component {
  //--------------------------------------
  //  Libs.Styless
  //--------------------------------------

  spanStyle = {
    fontPc: {
      fontSize: 12,
      fontFace: 'sansserif',
      lineHeight: 12,
      letterSpacing: -0.5,
      color: '#000'
    },
    fontSp: {
      fontSize: 12,
      fontFace: 'sansserif',
      lineHeight: 12,
      letterSpacing: -0.5,
      color: '#000'
    }
  }
  //--------------------------------------
  //  Scripts
  //--------------------------------------
  constructor (props) {
    super(props)
    this.container = React.createRef()
    this.mount = false
    const { dispatch } = this.props
    this.dispatch = dispatch
    this.langKey = Libs.Common.Func.getLangKey()
  }
  mount = false
  componentDidMount () {
    this.mount = true
  }

  render () {
    const containerCss = () => {
      //background-color: #f0F;
      return css`
        column-gap: var(--grid-gap);
        row-gap: var(--grid-gap);
        margin: 0 auto;

        ${Libs.Styles.Mq.morePc} {
          width: 92vw;
          display: grid;
          --grid-gap: 2rem;
          --grid-margin: 4rem;
          grid-template-columns: 3fr 1fr 1fr;
          padding-top: 25vh;
          padding-bottom: 2rem;
        }
        ${Libs.Styles.Mq.tb} {
          width: 94vw;
          display: grid;
          --grid-gap: 2rem;
          --grid-margin: 4rem;
          grid-template-columns: 3fr 1fr 1fr;
          padding-top: 25vh;
          padding-bottom: 2rem;
        }
        ${Libs.Styles.Mq.lessPab} {
          width: 90vw;
          display: grid;
          grid-template-columns: 150px 1fr;
          --grid-gap: 1rem;
          --grid-margin: 1.5rem;

          padding-top: 6rem;
          padding-bottom: 1.5rem;
        }

        .info-lw {
          ${Libs.Styles.Mq.lessPab} {
            grid-column: 1 / 3;
          }
        }
        .info-howlt {
          ${Libs.Styles.Mq.lessPab} {
            grid-column: 2 / 3;
          }
        }
        .info-hc {
          ${Libs.Styles.Mq.moreTab} {
            justify-self: right;
          }
          ${Libs.Styles.Mq.lessPab} {
            grid-column: 2 / 3;
            margin-bottom: 2rem;
          }
        }
      `
    }

    const loworksLogo = () => css`
      svg {
        height: 18px;
      }
      ${Libs.Styles.Mq.moreTab} {
      }
      ${Libs.Styles.Mq.lessPab} {
        grid-row: 1 / 2;
      }
    `
    const HowltLogo = () => css`
      svg {
        height: 18px;
      }
      ${Libs.Styles.Mq.moreTab} {
      }
      ${Libs.Styles.Mq.lessPab} {
        margin-top: 1.5rem;
        grid-column: 2 / 3;
        grid-row: 3 / 4;
      }
    `
    const HcLogo = () => css`
      svg {
        height: 18px;
      }
      ${Libs.Styles.Mq.moreTab} {
        justify-self: right;
      }
      ${Libs.Styles.Mq.lessPab} {
        margin-top: 2rem;
        grid-column: 2 / 3;
        grid-row: 5 / 6;
      }
    `
    const copy = () => css`
      justify-self: right;
      ${Libs.Styles.Mq.moreTab} {
        grid-column: 3 / 4;
      }
      ${Libs.Styles.Mq.lessPab} {
      }
    `

    return (
      <footer>
        <nav css={containerCss} ref={this.container} className='footer'>
          <Atoms.InfoLoworks></Atoms.InfoLoworks>
          <Atoms.InfoHowlt></Atoms.InfoHowlt>
          <Atoms.InfoHc></Atoms.InfoHc>
          <h2 css={loworksLogo}>
            <Atoms.Logo />
          </h2>
          <h2 css={HowltLogo}>
            <Atoms.LogoHowlt />
          </h2>
          <h2 css={HcLogo}>
            <Atoms.Logo02 />
          </h2>
          <div>
            <a href='https://loworks.co.jp' target='_new'>
              <Libs.Atoms.Span styles={this.spanStyle}>
                Site by LOWORKS Inc.
              </Libs.Atoms.Span>
            </a>
          </div>
          <div css={copy}>
            <a href='https://loworks.co.jp' target='_new'>
              <Libs.Atoms.Span styles={this.spanStyle}>
                ©2022 LOWORKS Inc. BK . NY
              </Libs.Atoms.Span>
            </a>
          </div>
        </nav>
      </footer>
    )
  }
}
export default Footer
