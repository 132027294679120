import React, { Component } from 'react'
import { css } from '@emotion/react'
import * as Style from '../../libs/styles'
class LoadingText extends Component {
  render () {
    return (
      <svg
        css={style}
        className='load-text'
        viewBox='0 0 78 54'
        preserveAspectRatio='xMinYMin meet'
      >
        <use xlinkHref='#loading-text' x='0' y='0' />
      </svg>
    )
  }
}
const style = () => css`
  height: 68px;
  fill: #000;
  ${Style.Mq.sp} {
    height: 43px;
  }
`
export default LoadingText
