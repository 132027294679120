import React, { Component } from 'react'
import { css } from '@emotion/react'
import * as Style from '../../libs/styles'
class Logo extends Component {
  render () {
    return (
      <svg css={style} viewBox='0 0 210 26' preserveAspectRatio='xMinYMin meet'>
        <use xlinkHref='#logo' x='0' y='0' />
      </svg>
    )
  }
}
const style = () => css`
  height: 68px;
  fill: #000;
  ${Style.Mq.sp} {
    height: 30px;
  }
`
export default Logo
