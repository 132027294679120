import React, { PureComponent } from 'react'
import { css } from '@emotion/react'
import * as Font from '../../styles/fontface'
import * as Transition from '../Transition'
import { connect } from 'react-redux'
import * as Libs from 'libs'

class Breadcrumb extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      ready: false
    }
  }
  componentDidMount () {
    let { current } = this.props
    this.init = false
    const section = document.querySelectorAll('section:first-child')
    this.dataset = section[0] ? section[0].dataset : null
    this.setState({ ready: true })
  }

  render () {
    if (!this.state.ready || !this.dataset) return null
    let count = 1
    let { current, prev, next, category, pageType, ...rest } = this.props
    if (!current) {
      let locationHref = window.location.href
      var pos = locationHref.indexOf('?')
      if (pos < 0) {
      } else {
        locationHref = locationHref.substring(0, pos)
      }
      const filename = Libs.Common.Func.getFileName(locationHref)
      prev = this.dataset.prev
      next = this.dataset.next
      current = this.dataset.currentname
        ? { name: this.dataset.currentname, slug: this.dataset.current }
        : { name: Libs.Common.Func.toUpperCaseFiest(filename), slug: filename }
      category = this.dataset.categoryname
        ? {
            name: this.dataset.categoryname,
            slug: this.dataset.categoryslug
          }
        : null

      if (category && category.slug !== current) {
        pageType = 'post'
      }
    }
    let langKey = Libs.Common.Func.getLangKey()
    const list = []
    list.push(current)

    const contCss = type => {
      return css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--grid-gap);
        row-gap: var(--grid-gap);

        width: 92vw;
        left: 50%;

        transform: translateX(-50%);
        position: absolute;
        .type-post & {
          max-width: 1600px;
        }
        a {
          color: #fff;
        }
        ${Libs.Styles.Mq.morePc} {
          --grid-gap: 0.5rem;
        }
        ${Libs.Styles.Mq.lessTab} {
          --grid-gap: 1rem;
        }
      `
    }
    const pagingContCss = () => {
      return css`
        transition: all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        transform-origin: right top;
        justify-self: right;

        ${Libs.Styles.Mq.moreTab} {
          margin-right: min(11.5vw, 124px);
        }
        ${Libs.Styles.Mq.pab} {
        }
        .on-scrollinit & {
          ${Libs.Styles.Mq.moreTab} {
            transform: scale(0.4);
          }
          ${Libs.Styles.Mq.lessPab} {
          }
        }
      `
    }

    const categoryCss = css`
      text-transform: capitalize;
    `

    const prevCss = css`
      display: inline-block;
      pointer-events: auto;
      position: relative;
      ${Libs.Common.Func.getPcSpVwValue('padding-right', 30, 10, true)};

      &:after {
        content: '';
        position: absolute;
        ${Libs.Common.Func.getPcSpVwValue('height', 40, 13, true)};
        ${Libs.Common.Func.getPcSpVwValue('width', 4, 2, true)};
        ${Libs.Common.Func.getPcSpVwValue('bottom', 8, 3, true)};
        ${Libs.Common.Func.getPcSpVwValue('margin-left', 13, 3, true)};
        background-color: #fff;
      }
    `
    const nextCss = css`
      display: inline-block;
      pointer-events: auto;
    `
    const ulCss = css`
      display: inline-block;
      transition: all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
      transform-origin: left top;
      .on-scrollinit & {
        ${Libs.Styles.Mq.moreTab} {
          transform: scale(0.4);
        }
        ${Libs.Styles.Mq.lessPab} {
        }
      }
      li {
        pointer-events: auto;
        vertical-align: middle;
        a {
          line-height: 1;
        }
        ${Libs.Styles.Mq.lessTab} {
          margin-right: ${Libs.Common.Func.getVwValue(1)}vw;
        }
        :not(&:first-child, &:last-child) {
          margin-left: ${Libs.Common.Func.getVwValue(1)}vw;
        }
        display: inline-block;
        &:after {
          content: '>';
          color: #fff;
          margin-right: ${Libs.Common.Func.getVwValue(0.75)}vw;
          margin-left: ${Libs.Common.Func.getVwValue(1)}vw;
          ${Libs.Common.Func.getPcSpVwValue('font-size', 24, 18, true)};
          ${Libs.Common.Func.getPcSpVwValue('line-height', 24, 18, true)};
          vertical-align: middle;

          ${Libs.Styles.Font.Bold()}
        }
        span {
          vertical-align: middle;
        }
        .type-category &:last-child {
          transition: all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
          display: block;
          span {
            transition: all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
            pointer-events: none;
            ${Libs.Common.Func.getPcSpVwValue('margin-top', 7, 5)};
          }
        }

        &:last-child {
          margin-left: 0;

          &:after {
            content: '';
            margin-left: 0;
          }
        }
      }
    `
    const getStyleObject = align => {
      return {
        fontPc: {
          fontSize: 54,
          textAlign: `${align}`,
          fontFace: 'sansserif',
          bold: true,
          letterSpacing: '-1.5'
        },
        fontSp: {
          fontSize: 18,
          fontFace: 'sansserif',
          bold: true,
          letterSpacing: '-.5'
        }
      }
    }
    const clickHandler = () => {
      const { dispatch } = this.props
      let langKey = Libs.Common.Func.getLangKey()

      const node = document.querySelector('.project-container')
      const dataset = node.dataset
      let slug = null
      let category = dataset.categoryslug
      if (category === 'works') {
        slug = dataset.current
      }

      Transition.TrigerFadeTransition.TrigerFadeTransition(
        `/${langKey}/${category}/`,
        dispatch,
        slug
      )
    }

    const getCagegory = props => {
      if (category != null) {
        count++
        return (
          <>
            <li
              itemprop='itemListElement'
              itemscope
              itemtype='http://schema.org/ListItem'
            >
              <a onClick={clickHandler}>
                <Libs.Atoms.Span
                  className={'bc-button'}
                  itemProp='name'
                  css={categoryCss}
                  styles={getStyleObject('left')}
                >
                  {category.name}
                </Libs.Atoms.Span>
              </a>

              {/*
              <Libs.Atoms.TriggerLink
                transition={
                  Libs.Transition.TrigerPageTransition.TrigerPageTransition
                }
                to={`/${langKey}/${category.slug}/`}
              >
                <Libs.Atoms.Span
                  className={'bc-button'}
                  itemProp='name'
                  css={categoryCss}
                  styles={getStyleObject('left')}
                >
                  {category.name}
                </Libs.Atoms.Span>
              </Libs.Atoms.TriggerLink>*/}
            </li>
            <meta itemprop='position' content={count} />
          </>
        )
      }
    }
    const getPrevElement = props => {
      if (prev != null) {
        return (
          <div css={prevCss}>
            <Libs.Atoms.TriggerLink
              transition={
                Libs.Transition.TrigerPageTransition.TrigerPageTransition
              }
              to={`/${langKey}/${category.slug}/${prev}/`}
            >
              <Libs.Atoms.Span
                className={'bc-button'}
                styles={getStyleObject('right')}
              >
                PREV
              </Libs.Atoms.Span>
            </Libs.Atoms.TriggerLink>
          </div>
        )
      }
    }
    const getNextElement = props => {
      if (next != null) {
        return (
          <div css={nextCss}>
            <Libs.Atoms.TriggerLink
              transition={
                Libs.Transition.TrigerPageTransition.TrigerPageTransition
              }
              to={`/${langKey}/${category.slug}/${next}/`}
            >
              <Libs.Atoms.Span
                className={'bc-button'}
                styles={getStyleObject('right')}
              >
                NEXT
              </Libs.Atoms.Span>
            </Libs.Atoms.TriggerLink>
          </div>
        )
      }
    }

    if (
      current.slug == 'index' ||
      current.slug === 'ja' ||
      current.slug === 'en'
    ) {
      return <></>
    }

    return (
      <div css={contCss} {...rest}>
        <ol itemscope itemtype='http://schema.org/BreadcrumbList' css={ulCss}>
          <li
            itemprop='itemListElement'
            itemscope
            itemtype='http://schema.org/ListItem'
          >
            <Libs.Atoms.TriggerLink
              transition={
                Libs.Transition.TrigerPageTransition.TrigerPageTransition
              }
              to={`/${langKey}`}
            >
              <Libs.Atoms.Span
                className={'bc-button'}
                itemProp='name'
                styles={getStyleObject()}
              >
                Home
              </Libs.Atoms.Span>
            </Libs.Atoms.TriggerLink>
            <meta itemprop='position' content={count} />
          </li>
          {getCagegory()}
        </ol>
        <div css={pagingContCss}>
          {getPrevElement()}
          {getNextElement()}
        </div>
        {/*<div css={grid_full}>
          {list.map((item, i) => {
            return (
              <div
                itemprop='itemListElement'
                itemscope
                itemtype='http://schema.org/ListItem'
                key={`breadcrumbItem${i}`}
              >
                <Libs.Atoms.TriggerLink
                  transition={
                    Libs.Transition.TrigerPageTransition.TrigerPageTransition
                  }
                  to={`/${langKey}/${category ? category.slug + '/' : ''}${
                    item.slug
                  } `}
                >
                  <Libs.Atoms.Span
                    className={'bc-button'}
                    itemProp='name'
                    styles={getStyleObject()}
                  >
                    {item.name}
                  </Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
                <meta itemprop='position' content={i + 1 + count} />
              </div>
            )
          })}
        </div>*/}
      </div>
    )
  }
}

export default connect(state => ({
  current: state.TransitionReducer.current,
  pageType: state.TransitionReducer.pageType,
  category: state.TransitionReducer.category,
  prev: state.TransitionReducer.prev,
  next: state.TransitionReducer.next
}))(Breadcrumb)
