import React, { useEffect, useState, useRef } from 'react'

import { css } from '@emotion/react'

import * as Libs from 'libs'
import { Power3 } from 'gsap'

import { TimelineMax } from 'gsap'

export default props => {
  //--------------------------------------
  //  Constructor
  //--------------------------------------
  const videoEl = useRef(null)
  const [ready, setReady] = useState(false)

  let screenW
  if (typeof window !== 'undefined') {
    screenW = window.innerWidth
  }
  useEffect(() => {
    setReady(true)

    const ele = videoEl.current

    ele.addEventListener('loadedmetadata', event => {
      const tl = new TimelineMax()

      const cont = ele.closest('.video-inner-cont')
      tl.to({}, 0.5, {})
        .call(function () {
          cont.classList.add('loaded')
          cont.style.height = 'auto'
        })
        .to(
          ele,
          0.5,
          {
            opacity: 1,
            ease: Power3.easeInOut
          },
          'end'
        )
    })
  }, [])

  //--------------------------------------
  //  Render
  //--------------------------------------

  let { videoSrc, ratio, category, type } = props
  if (ratio === '') ratio = 1

  //--------------------------------------
  //  Css
  //--------------------------------------

  const imgLinkCss = props => {
    const spHeight = 80
    const pcHeight = 30
    const largeHeight = 23.5
    return css`
      ${Libs.Styles.Mq.tb} {
        height: 30vw;
      }
      ${Libs.Styles.Mq.pc} {
        height: ${pcHeight}vw;
      }
      ${Libs.Styles.Mq.large} {
        height: ${largeHeight}vw;
      }
      ${Libs.Styles.Mq.xlarge} {
        height: 20vw;
      }
      ${Libs.Styles.Mq.lessPab} {
        height: 38vw;
      }
      ${Libs.Styles.Mq.sp} {
        height: auto;
      }
    `
  }

  const imgCss = imgSrc => {
    const el = videoEl.current
    if (!el) return

    let w = (Math.sqrt(160 * ratio) / 16 / 0.9) * 100
    if (w > 100) {
      w = 100
    }

    const height = (screenW * 0.8 * w) / 100 / ratio

    return css`
      height: 100%;
      ${Libs.Styles.Mq.sp} {
        height: ${height}px;
      }
      .video-inner-cont {
        position: absolute !important;
        top: auto;
        left: 50%;
        margin-left: ${-w / 2}%;
        bottom: 0px;
        width: ${w}%;
        height: calc(${w}% * ${ratio});
      }
      position: relative;
      video {
        width: 100%;
        height: auto;
      }
    `
  }
  //--------------------------------------
  //  Styles
  //--------------------------------------

  //--------------------------------------
  //  Function
  //--------------------------------------

  //--------------------------------------
  //  Code
  //--------------------------------------

  return (
    <div css={imgCss(videoSrc)} className={`img-cont img-element `}>
      <div className='video-inner-cont'>
        <video
          autoplay='autoplay'
          muted='1'
          playsinline='1'
          loop='1'
          disablepictureinpicture='1'
          preload='1'
          data-plugin='videoAutoPlay'
          ref={videoEl}
        >
          <source src={videoSrc} type='video/mp4' />
        </video>
      </div>
    </div>
  )
}
