import React from 'react'
import { css } from '@emotion/react'
import * as Common from '../../common'

import * as Modules from '../modules'
import * as Libs from 'libs'

const pStyle = {
  fontPc: {
    fontFace: 'sansserif',
    fontSize: 14,
    lineHeight: 18,
    letterSpacing: -0.2,
    bold: true
  },
  fontSp: {
    fontFace: 'sansserif',
    fontSize: 14,
    lineHeight: 18,
    letterSpacing: -0.4,
    bold: true
  }
}

const marginCss = () => css`
  margin-top: 10px;
`
const addressGridCss = () => css`
  display: grid;
  --grid-gap: 0.5rem;

  grid-template-columns: 20px 1fr;
  column-gap: var(--grid-gap);
`

export const InfoLoworks = props => {
  const langKey = Libs.Common.Func.getLangKey()
  const infoLwGridCss = () => css`
    display: grid;
    --grid-gap: 1rem;
    --grid-margin: 1.5rem;
    column-gap: var(--grid-gap);
    row-gap: var(--grid-gap);
    grid-template-columns: 150px 1fr;
  `

  return (
    <div className='info-lw' css={infoLwGridCss}>
      <div>
        <Modules.GlobalNavigation styles={pStyle} />
        <ul>
          <li css={marginCss}>
            <Libs.Atoms.Span data-hover={'shopping-info'} styles={pStyle}>
              <Libs.Atoms.TriggerLink
                transition={
                  Libs.Transition.TrigerPageTransition.TrigerPageTransition
                }
                to={`/${langKey}/shopping-info`}
              >
                Shopping Info
              </Libs.Atoms.TriggerLink>
            </Libs.Atoms.Span>
          </li>
          <li>
            <Libs.Atoms.Span data-hover={'privacy-policy'} styles={pStyle}>
              <Libs.Atoms.TriggerLink
                transition={
                  Libs.Transition.TrigerPageTransition.TrigerPageTransition
                }
                to={`/${langKey}/privacy-policy`}
              >
                Privacy Policy
              </Libs.Atoms.TriggerLink>
            </Libs.Atoms.Span>
          </li>
        </ul>
      </div>
      <InfoLwAddress />
    </div>
  )
}
export const InfoLwAddress = props => {
  return (
    <div className='info-lw-address'>
      <ul>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='mailto:info@loworks.co.jp' target='_new'>
              info@loworks.co.jp
            </a>
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='https://www.instagram.com/loworks_inc/' target='_new'>
              Instagram
            </a>
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='https://www.facebook.com/loworks/' target='_new'>
              Facebook
            </a>
          </Libs.Atoms.Span>
        </li>
      </ul>
      <div css={marginCss} className='address-cont'>
        <div css={addressGridCss}>
          <Libs.Atoms.P styles={pStyle}>US.</Libs.Atoms.P>
          <Libs.Atoms.P styles={pStyle}>
            C23 266 Kent Ave, Brooklyn, NY 11249
          </Libs.Atoms.P>
          <Libs.Atoms.P styles={pStyle}>JP.</Libs.Atoms.P>
          <Libs.Atoms.P styles={pStyle}>
            3F 3-1-9 Shibuya Shibuya-ku Tokyo 150-0002
          </Libs.Atoms.P>
        </div>
      </div>
    </div>
  )
}

export const InfoHowlt = props => {
  return (
    <div className='info-howlt'>
      <ul>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='https://howlt.com' target='_new'>
              howlt.com
            </a>
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='https://www.instagram.com/howlt/' target='_new'>
              Instagram
            </a>
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='https://www.facebook.com/howlt/' target='_new'>
              Facebook
            </a>
          </Libs.Atoms.Span>
        </li>
      </ul>
    </div>
  )
}

export const InfoHc = props => {
  return (
    <div className='info-hc'>
      <ul>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='https://howlt-coffee.com' target='_new'>
              howlt-coffee.com
            </a>
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='https://www.instagram.com/howltcoffee/' target='_new'>
              Instagram
            </a>
          </Libs.Atoms.Span>
        </li>
        <li>
          <Libs.Atoms.Span styles={pStyle}>
            <a href='https://www.facebook.com/howltcoffee/' target='_new'>
              Facebook
            </a>
          </Libs.Atoms.Span>
        </li>
      </ul>
      <div css={marginCss} className='hc-address'>
        <InfoHcAddress styles={pStyle} />
      </div>
    </div>
  )
}

export const InfoHcAddress = prop => {
  const { styles, ...rest } = prop

  return (
    <>
      <Libs.Atoms.P styles={styles}>
        <a
          href='https://g.page/howltcoffee?share'
          target='_new'
          rel='noopener noreferrer'
        >
          2-6-11 Daimyo Chuo-ku
          <br />
          Fukuoka Japan
          <br /> 810-0041
        </a>
      </Libs.Atoms.P>
      <Libs.Atoms.P css={marginCss} styles={styles}>
        Sat-Sun 1:00 PM — 6:30 PM
        <br />
        Mon-Fri 1:00 PM — 5:00 PM
      </Libs.Atoms.P>
    </>
  )
}
export const InfoContactForHader = props => {
  const animationCont = () => css`
    overflow: hidden;
    h3,
    p {
      transition: all 0.75s cubic-bezier(0.71, 0.01, 0.45, 1.01);
      .category-index & {
        transform: translateY(0%);
      }
    }
    h3 {
      transform: translateY(-145%);

      ${Libs.Styles.Mq.moreTab} {
        .category-index.on-scrollinit &,
        .now-transition & {
          transform: translateY(-145%);
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        .category-index.on-gnav-out &,
        .now-transition & {
          transform: translateY(-145%);
        }
      }
    }

    p {
      transform: translateY(-125%);

      ${Libs.Styles.Mq.moreTab} {
        .category-index.on-scrollinit &,
        .now-transition & {
          transform: translateY(-125%);
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        .category-index.on-gnav-out &,
        .now-transition & {
          transform: translateY(-125%);
        }
      }
    }
  `
  const hStyle = {
    fontPc: {
      fontFace: 'sansserif',
      fontSize: 26,
      lineHeight: 26,
      letterSpacing: -0.4,
      bold: true
    },
    fontSp: {
      fontFace: 'sansserif',
      fontSize: 26,
      lineHeight: 26,
      letterSpacing: -0.4,
      bold: true
    },
    positionPc: { space: '10 0 7 0' },
    positionSp: { space: '0 0 7 0' }
  }

  return (
    <div>
      <div css={animationCont}>
        <Libs.Atoms.H3 styles={hStyle}>INQUIRE</Libs.Atoms.H3>
      </div>
      <div css={addressGridCss}>
        <div css={animationCont}>
          <Libs.Atoms.P styles={pStyle}>US.</Libs.Atoms.P>
        </div>
        <div css={animationCont}>
          <Libs.Atoms.P styles={pStyle}>266 Kent Brooklyn NY</Libs.Atoms.P>
        </div>
        <div css={animationCont}>
          <Libs.Atoms.P styles={pStyle}>JP.</Libs.Atoms.P>
        </div>
        <div css={animationCont}>
          <Libs.Atoms.P styles={pStyle}>3-1-9 Shibuya-ku Shibuya</Libs.Atoms.P>
        </div>
        <div css={animationCont}>
          <Libs.Atoms.P styles={pStyle}>E.</Libs.Atoms.P>
        </div>
        <div css={animationCont}>
          <Libs.Atoms.P styles={pStyle}>info@loworks.co.jp</Libs.Atoms.P>
        </div>
      </div>
    </div>
  )
}
