import React, { PureComponent } from 'react'
import { OverlayOpen, OverlayClose } from '../redux/action'
import { css } from '@emotion/react'

import * as Modules from './'
import { connect } from 'react-redux'

import * as Atoms from '../atoms'

export class SelectHeader extends PureComponent {
  clickHandler = e => {
    const {
      dispatch,
      selectListHeaderStyle = {
        italic: true,
        color: '#acacac',
        pcFontSize: 16,
        spFontSize: 16,
        letterSpacing: 0.43,
        fontFace: 'serif',
        pcLineHeight: 16,
        spLineHeight: 16,
        display: 'inline'
      },
      selectListSelectStyle = {
        bold: true,
        italic: false,
        pcFontSize: 16,
        spFontSize: 16,
        letterSpacing: 0.43,
        fontFace: 'serif',
        pcLineHeight: 16,
        spLineHeight: 16
      },
      ...rest
    } = this.props
    rest.currentValue = this.currentValue

    dispatch(
      OverlayOpen({
        element: Modules.SelectList,
        props: {
          onClick: function (event) {
            dispatch(OverlayClose())
          },
          headerStyles: selectListHeaderStyle,
          selectStyles: selectListSelectStyle,
          ...rest
        }
      })
    )
  }
  constructor (props) {
    super(props)

    this.currentValue = 'all'
  }
  render () {
    const {
      header,
      values,
      current,
      headerStyle = {
        italic: true,
        color: '#acacac',
        pcFontSize: 16,
        spFontSize: 16,
        letterSpacing: 0.43,
        fontFace: 'serif',
        pcLineHeight: 16,
        spLineHeight: 16,
        display: 'inline'
      },
      selectStyle = {
        bold: true,
        italic: false,
        pcFontSize: 16,
        spFontSize: 16,
        letterSpacing: 0.43,
        fontFace: 'serif',
        pcLineHeight: 16,
        spLineHeight: 16
      }
    } = this.props
    let name = 'ALL'
    if (current) {
      this.currentValue = current
      name = values.get(current).name
    } else {
      this.currentValue = 'all'
      name = 'ALL'
    }
    const headerCss = () =>
      css`
        display: inline-block;
        &::after {
          content: '>';
          margin: 0 3px 0 3px;
        }
      `

    return (
      <div
        onClick={event => {
          event.preventDefault()
          this.clickHandler(event)
        }}
      >
        <Atoms.H4 styles={headerStyle} css={headerCss}>{`${header}`}</Atoms.H4>
        <Atoms.Span styles={selectStyle}>{name}</Atoms.Span>
      </div>
    )
  }
}

const mapStateToProps = (state, filter) => {
  return {
    slug: state.SelectReducer.slug,
    value: state.SelectReducer.value,
    currentValue: state.SelectReducer.currentValue
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  SelectHeader
)
