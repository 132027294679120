import { useStaticQuery, graphql } from 'gatsby'

export const useEnPostData = () => {
  const { allContentfulContentPost } = useStaticQuery(
    graphql`
      query postListEnQuery {
        allContentfulContentPost(filter: { node_locale: { eq: "en" } }) {
          edges {
            node {
              id
              slug
              title
              date
              name
              description {
                raw
              }

              category {
                slug
                name
              }

              updatedAt(formatString: "MMMM DD, YYYY, h:mm:ss")

              node_locale
              thumbnail {
                gatsbyImageData(width: 1800, quality: 70)
              }
              tags {
                name
                slug
                group
              }
            }
          }
        }
      }
    `
  )
  return allContentfulContentPost.edges
}
