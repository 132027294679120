import { css } from '@emotion/react'
import * as Font from './fontface'
import * as Common from '../common'
import * as Styles from '.'

export const ContentFulCss = props => css`
  ${Styles.RichTextCss.RichTextCss()}

  .embed-Vimeo {
    iframe {
      pointer-events: none;
    }
    .vp-player-ui-overlays {
      display: none !important;
      visibility: hidden !important;
    }
    .vp-controls-wrapper {
      display: none !important;
      visibility: hidden !important;
    }
    .vp-unmute {
      display: none !important;
      visibility: hidden !important;
    }
  }
`
