import React from 'react'
import { css } from '@emotion/react'
import * as Hooks from '../../hooks'

import * as Libs from 'libs'

import { connect } from 'react-redux'
export const TagFilter = props => {
  //--------------------------------------
  //  Css
  //--------------------------------------

  const contCss = () =>
    css`
      position: relative;
      width: 92vw;
      margin-left: 4vw;
      ${Libs.Styles.Mq.lessPab} {
        margin-left: 5vw;
        width: 90vw;
      }
    `
  const tagFilterContCss = () =>
    css`
      ${Libs.Styles.Mq.lessPab} {
        display: flex;
        justify-content: space-between;
        position: relative;
        ${Libs.Common.Func.getPcSpVwValue('margin-top', -15, 10, false)};
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #000;
          position: absolute;
          ${Libs.Common.Func.getPcSpVwValue('bottom', -15, -10, false)};
        }
      }
    `

  const tfListCont = () =>
    css`
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        ${Libs.Common.Func.getPcSpVwValue('bottom', -15, 120, false)};
      }
    `
  const tfListHeader = () =>
    css`
      position: absolute;
      ${Libs.Common.Func.getPcSpVwValue('bottom', -36, 120, false)};
    `

  const tfListList = () =>
    css`
      display: flex;
      justify-content: space-between;
    `
  const tfSelect = () => css`
    ${Libs.Styles.Mq.moreTab} {
      position: absolute;
      top: -60px;
      &:nth-child(2) {
        left: 0px;
      }
      &:nth-child(3) {
        ${Libs.Common.Func.getPcSpVwValue('left', 120, 120, false)};
      }
    }
    ${Libs.Styles.Mq.lessPab} {
    }
  `

  //--------------------------------------
  //  Function
  //--------------------------------------

  const { current, ...rest } = props

  const category = props.siteCategory
    ? props.siteCategory.slug
    : props.category
    ? props.category.slug
    : null

  let data =
    category == 'works'
      ? Hooks.WorksCategoryTagData.WorksCategoryTagData()
      : Hooks.JouranlCategoryTagData.JournalCategoryTagData()

  const taglists = data ? data['content___post'] : null
  const tagsMap = new Map()
  if (taglists) {
    taglists.map((item, i) => {
      if (item.tags) {
        item.tags.map(tag => {
          if (!tagsMap.has(tag.group)) {
            const newMap = new Map()
            newMap.set(tag.slug, tag, category)
            tag.category = category
            tagsMap.set(tag.group, newMap)
          } else {
            const tmp = tagsMap.get(tag.group)
            tag.category = category

            tmp.set(tag.slug, tag)
          }
        })
      }
    })
  }

  const sortFunc = (a, b) => {
    a = a.toString().toLowerCase()
    b = b.toString().toLowerCase()
    if (a < b) return -1
    else if (a > b) return 1
    return 0
  }
  const headerFilterStyle = {
    fontSp: {
      fontSize: 24,
      lineHeight: 24,
      bold: true,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    }
  }

  const headerStyle = {
    fontPc: {
      fontSize: 12,
      lineHeight: 12,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    },
    fontSp: {
      fontSize: 12,
      lineHeight: 12,
      fontFace: 'sansserif',
      letterSpacing: '-.2'
    }
  }
  const selectStyle = {
    fontPc: {
      fontSize: 12,
      lineHeight: 12,
      bold: true,
      fontFace: 'sansserif',
      letterSpacing: '-.1'
    },
    fontSp: {
      fontSize: 12,
      lineHeight: 12,
      bold: true,
      fontFace: 'sansserif',
      letterSpacing: '-.1'
    }
  }
  const selectListSelectStyle = {
    fontPc: {
      fontSize: 48,
      lineHeight: 60,
      bold: true,
      fontFace: 'sansserif',
      letterSpacing: '-.5'
    },
    fontSp: {
      fontSize: 36,
      lineHeight: 48,
      bold: true,
      fontFace: 'sansserif',
      letterSpacing: '-.5'
    }
  }

  const listTextCss = {
    fontPc: {
      fontSize: 36,
      bold: true,
      fontFace: 'sansserif',
      letterSpacing: '-1.5'
    },
    fontSp: {
      fontSize: 16,
      fontFace: 'sansserif',
      letterSpacing: '-.5'
    }
  }

  let langKey = Libs.Common.Func.getLangKey()
  let languages = Libs.Common.Config.languages
  langKey = languages.langs.length > 1 ? `/${langKey}` : ``
  let isMobile = false
  if (Libs.Common.Func.getDeviceSize() === 'lessPab') {
    isMobile = true
  }
  //--------------------------------------
  //  Code
  //--------------------------------------

  return (
    <div css={contCss} {...rest}>
      {isMobile ? (
        <Libs.Atoms.H2 styles={headerFilterStyle}>Filter</Libs.Atoms.H2>
      ) : (
        ''
      )}
      <div css={tagFilterContCss}>
        {taglists
          ? Object.entries(Libs.Common.Func.mapToObject(tagsMap)).map(
              (taglist, i) => {
                const currentMap = taglist[1].get(
                  current ? current.slug : 'all'
                )
                if (taglist[0] === 'Category' && !isMobile) {
                  return (
                    <div css={tfListCont}>
                      <Libs.Atoms.H3 css={tfListHeader} styles={headerStyle}>
                        {taglist[0]}
                      </Libs.Atoms.H3>
                      <ul className='tf-list' css={tfListList}>
                        <li>
                          <Libs.Atoms.TriggerLink
                            to={`${langKey}/${category}/`}
                          >
                            <Libs.Atoms.Span styles={listTextCss}>
                              {'ALL'}
                            </Libs.Atoms.Span>
                          </Libs.Atoms.TriggerLink>
                        </li>

                        {Object.entries(
                          Libs.Common.Func.mapToObject(taglist[1])
                        ).map((item, i) => {
                          const to = `${langKey}/${category}/${item[1].slug}/`
                          return (
                            <li>
                              <Libs.Atoms.TriggerLink to={to}>
                                <Libs.Atoms.Span styles={listTextCss}>
                                  {item[1].name}
                                </Libs.Atoms.Span>
                              </Libs.Atoms.TriggerLink>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )
                } else {
                  return (
                    <div className='tf-select' css={tfSelect}>
                      <Libs.Modules.SelectHeader
                        header={taglist[0]}
                        values={taglist[1]}
                        headerStyle={headerStyle}
                        selectStyle={selectStyle}
                        selectListHeaderStyle={headerStyle}
                        selectListSelectStyle={selectListSelectStyle}
                        category={category}
                        current={currentMap ? currentMap.slug : null}
                      />
                    </div>
                  )
                }
              }
            )
          : ''}
      </div>
    </div>
  )
}
export default connect(state => ({
  current: state.TransitionReducer.current,
  category: state.TransitionReducer.category,
  prev: state.TransitionReducer.prev,
  next: state.TransitionReducer.next
}))(TagFilter)
